import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./style.css";
import Navbar from "./Navbar";
import Footer from "./Footer";
import CustomerApi from '../services/CustomerAPI';
import { Link, useHistory } from 'react-router-dom';

const CustomerRegister = () => {
  
  // const navigate = useNavigate();
  const history = useHistory();
  const [customer, setCustomer] = useState([]);
  const [formData, setFormData] = useState({
    firstName: "",
    number: "",
    email: "",
    customerStatus: true,
    password: "",
    areaAddress: "",
    agreeToTerms: false,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    try {
      const response = await CustomerApi.getCustomerSingUp(formData);
      const responseData = response.data;
      history.push("/customerLogin");
      // Check the response from the server and handle accordingly
      if (responseData.success) {
        console.log("Registration successful");
        // Optionally, you can navigate to another page after successful registration
        // navigate("/login");
      } else {
        console.error("Registration failed:", responseData.message);
      }
    } catch (error) {
      console.error("Error during registration:", error);
    }
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  return (
    <>
      <Navbar />
      <div class="container pt-4 pb-4">
        <div class="row login-row">
          <div class="col-lg-5">
            <div class="login-img">
              <div>
                <img src="/login-img.png" alt="" />
                <h5>
                  Instant order <br />& Buy
                </h5>
                <h6>
                  Triclomate will receive your order <br /> and be able to reply to
                  you once. <br /> You place an order{" "}
                </h6>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="login-form">
              <div className="d-flex" style={{ alignItems: "center" }}>
                <h6>Triclomate</h6>
              </div>
              <h4>Register</h4>
              <p className="mb-4">
                Register to make an order, access your orders, special offers,
                and <br /> more!{" "}
              </p>
              <div className="login-line">
              <form onSubmit={handleSubmit} className="pt-4">
                <div className="mb-3 email-form">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Username
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter your name"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-4 pass-form">
                  <label htmlFor="exampleInputPassword1" className="form-label">
                  Phone Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputPassword1"
                    placeholder="Enter your phone number"
                    name="number"
                    value={formData.PhoneNumber}
                    onChange={handleChange}
                    required
                  />
                </div>

                {/* <div className="mb-3 email-form">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Phone Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter your customer phone number"
                    // autoComplete="off"
                    // onKeyPress={(event) => {
                    //   const charCode = event.charCode;
                    //   if (
                    //     charCode < 48 ||
                    //     charCode > 57 ||
                    //     event.target.value.length >= 10
                    //   ) {
                    //     event.preventDefault();
                    //   }
                    // }}
                    name="phone"
                    value={formData.number}
                    onChange={handleChange}
                    required
                  />
                </div> */}
                <div className="mb-3 email-form">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="emailId@gmail.com"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-4 pass-form">
                  <label htmlFor="exampleInputPassword1" className="form-label">
                  Full Address
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputPassword1"
                    placeholder="Enter your Location"
                    name="areaAddress"
                    value={formData.areaAddress}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-4 pass-form">
                  <label htmlFor="exampleInputPassword1" className="form-label">
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="exampleInputPassword1"
                    placeholder="**********"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3 form-check login-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                  />
                  <label className="form-check-label" htmlFor="exampleCheck1">
                    I've read and agree with the{" "}
                    <Link to="/termUse">Terms of services</Link>, <br />
                    <Link to="/privacyPolicy">Privacy Policy</Link> and{" "}
                    <Link to="/refundPolicy">Return and Refund Policy</Link>
                  </label>
                </div>
                <div class="d-grid">
                    <button class="btn btn-secondary btn-lg btn-rounded order-yellow-btn">Register</button>
                </div>                 
              <small>
                Already have an account?{" "}
                <Link to="/customerLogin" className="switch-form">
                Login
                </Link>
              </small>
              </form>
            </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CustomerRegister;