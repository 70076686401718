import React from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./style.css";
import Footer from "./Footer";
import Navbar from "./Navbar";

const RefundPolicy = () => {
  return (
    <>
      <Navbar />

      <div className="container pt-4 mb-4">
        <div className="row">
          <div className="col-lg-3">
            <div className="side-nav-policy">
              <Link to="/privacyPolicy">
                <p>
                  <img src="images/privacy.png" alt="" /> &nbsp; Privacy Policy
                </p>
              </Link>
              <Link to="/termUse">
                <p>
                  <img src="images/term.png" alt="" /> &nbsp; Terms of use
                </p>
              </Link>
              <Link to="/termsCondition">
                <p>
                  <img src="images/condition.png" alt="" /> &nbsp; Terms and
                  conditions
                </p>
              </Link>
              <Link to="/refundPolicy">
                <p className="active">
                  <img src="images/ref.png" alt="" /> &nbsp; Refund Policy
                </p>
              </Link>
            </div>
          </div>
          <div className="col-lg-9">
          <div class="terms-page">
            <h4>Refund Policy</h4>
            <h5>What are the conditions for which I can return the products?</h5>
            <p>
              1. If the product you recieved , is damaged or tampered or leaked.
            </p>
            <p>
             2. If you have recieved incomplete order.
            </p>
            <p>
              3. If the product is expired.
            </p>
            <p>
              4. If you have recieved wrong products.
            </p>
            <h4>What are the conditions under which my return /replacement request will not be accepted?</h4>
            <p>
             1. If the product is missing the original label, packaging ,moocartons.
            </p>
            <p>
            2. If the return request is generated after 7 days from the date of delivery.
            </p>
            <p>
              3. If you have opened /used/altered the products.
            </p>
            <h4>How is my refund request processed?</h4>
            <p>
            After you have placed a request to return your products , Our team will review you request within 2-3 busines days, and an agent will pickup you package within 5-7 business days.. Once the item is brought back to our warehouse ,our Quality control team will check for the return parameters ,and you will be refunded via your original mode of payment .
            </p>
            <h4>Can I cancel my order?</h4>
            <p>
            You can cancel order from CANCEL button from order details page under orders dashboard. You just need to give reason for cancellation, and leave rest on us.
            </p>
            <p>OR</p>
            <p>** Triclomate reserves the right to cancel any order without pre-confirming the customer at any time and may verify any order before shipping the same to the customer that may include having a verbal or written confirmation from the customer.</p>
          </div>
        </div>
      </div>
    </div>

    <Footer />
    </>
  );
};

export default RefundPolicy;