import React from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./style.css";
import Footer from "./Footer";
import Navbar from "./Navbar";

const TermUse = () => {
  return (
    <>
      <Navbar />

      <div className="container pt-4 mb-4">
        <div className="row">
          <div className="col-lg-3">
            <div className="side-nav-policy">
              <Link to="/privacyPolicy">
                <p>
                  <img src="images/privacy.png" alt="" /> &nbsp; Privacy Policy
                </p>
              </Link>
              <Link to="/termUse">
                <p className="active">
                  <img src="images/term.png" alt="" /> &nbsp; Terms of use
                </p>
              </Link>
              <Link to="/termsCondition">
                <p>
                  <img src="images/condition.png" alt="" /> &nbsp; Terms and
                  conditions
                </p>
              </Link>
              <Link to="/refundPolicy">
                <p>
                  <img src="images/ref.png" alt="" /> &nbsp; Refund Policy
                </p>
              </Link>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="terms-page">
              
            <h4>Terms of Use</h4>
            <p>
            Standard terms and conditions for online stores usually include details about payment terms, acceptable website or app usage, dispute resolutions, and more. A Terms and Conditions policy is a legal document that is created and hosted on your ecommerce site to protect your business. It informs your website visitors what will be legally required of them if they use your service.
            </p>
          <h4>Website Terms and Conditions</h4>
          <p>A Terms & Conditions document for a website is an agreement the website makes with its users about how to use the site properly, as well as the obligations and responsibilities of each party. Websites, especially commercial websites, have a portion of the site devoted to Terms & Conditions because it's the most important document for letting site users know what is expected of them. For owners/operators running any kind of business or personal website that allows user interaction, it's a good idea to have a clearly laid out document with all the Terms & Conditions required. </p>
         <p>The Terms & Conditions document on any website creates a legally binding set of rules for the user, as well as, in most cases, the website owner. It's a place to set up expectations for each of the parties and ensure that the use of the website runs smoothly for both.</p>
          </div>
        </div>
      </div>
    </div>

    <Footer />
    </>
  );
};

export default TermUse;