import React, { useEffect, useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./style.css";
import CustomerApi from "../services/CustomerAPI";
import { useParams } from "react-router-dom";
import ProductsAPI from "../services/ProductsAPI";

const Navbar = () => {
  
  // const navigate = useNavigate();  
  const history = useHistory();
  const { customerId } = useParams();
  const { productId } = useParams();
  const [customerDetails, setCustomerDetails] = useState(null);
  const [productDetails, setProductDetails] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // New state for login status

  useEffect(() => {
    console.log("Current productId:", productId);

    if (productId && typeof productId === "string" && productId.trim() !== "") {
      const fetchProductDetails = async () => {
        try {
          const response = await ProductsAPI.getProductDetails(productId);
          setProductDetails(response.data);
        } catch (error) {
          console.error("Error fetching product details:", error);
        }
      };

      fetchProductDetails();
    } else {
      console.error("ProductId is undefined or invalid");
      // Handle this case as needed, e.g., redirect or display an error message
    }
  }, [productId]);

  useEffect(() => {
    console.log("Current customerId:", customerId);

    if (
      customerId &&
      typeof customerId === "string" &&
      customerId.trim() !== ""
    ) {
      const fetchCustomerDetails = async () => {
        try {
          const response = await CustomerApi.getCustomerrDetails(customerId);
          setCustomerDetails(response.data);
          setIsLoggedIn(true); // Set login status to true when details are fetched
        } catch (error) {
          console.error("Error fetching customer details:", error);
          setIsLoggedIn(false); // Set login status to false if there's an error
        }
      };

      fetchCustomerDetails();
    } else {
      console.error("CustomerId is undefined or invalid");
      setIsLoggedIn(false); // Set login status to false if there's no customerId
      // Handle this case as needed, e.g., redirect or display an error message
    }
  }, [customerId]);

  const handleLogout = () => {
    // Remove the token from storage
    localStorage.removeItem("token");

    // Navigate to the login page or home page
    history.push("/customerLogin");
  };



  return (
    <>
      <div className="main-navbar sticky-top">
        <div className="top-navbar">
          <div className="container">
            <div className="row">
              <div className="col-md-2 my-auto d-none d-sm-none d-md-block d-lg-block">
                <h5 className="brand-name">
                  <Link to="/" className="nav-link home-login">
                    <img src={`${process.env.PUBLIC_URL}/Triclomate.png`}  alt="" srcSet="" height="70"/>{" "}
                    Triclomate
                  </Link>
                </h5>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-4 my-auto">
                <form role="search">
                  <div className="input-group nav-search">
                    <input
                      type="search"
                      placeholder="Search for an Item..."
                      className="form-control"
                    />
                    <button className="btn" type="submit">
                      <i className="fa fa-search"></i>
                    </button>
                  </div>
                </form>
              </div>
              <div className="col-md-5 my-auto">
                <ul className="nav justify-content-end">
                  {isLoggedIn ? (
                    // Show user-related content when logged in
                    <>
                      <li className="nav-item dropdown">
                        <a
                          className="nav-link dropdown-toggle"
                          href="#"
                          id="navbarDropdown"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img src={`${process.env.PUBLIC_URL}/user.jpg`} width="40" height="40" alt="" />
                          &nbsp; {customerDetails?.firstName || "User"}
                        </a>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                        >
                          <li>
                            <Link
                              className="dropdown-item"
                              to={`/userProfile/${customerId}`}
                            >
                              <i className="fa fa-user"></i> Profile
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to={`/myOrder/${customerId}`}
                            >
                              <i className="fa fa-list"></i> My Orders
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to={`/mycart/${customerId}`}
                            >
                              <i className="fa fa-shopping-cart"></i> My Cart
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="/"
                              onClick={handleLogout}
                            >
                              <i className="fa fa-sign-out"></i> Logout
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item pt-2">
                        <Link
                          className="nav-link"
                          to={`/mycart/${customerId}`}
                        >
                          Basket &nbsp; <i className="bi bi-basket"></i>
                        </Link>
                      </li>
                    </>
                  ) : (
                    // Show login button when not logged in
                    <>
                      <li className="nav-item pt-2">
                        <Link
                          to="/customerLogin"
                          className="nav-link home-login"
                        >
                          <span className="nav-link-name">Login</span>
                        </Link>
                      </li>
                      <div className="dropdown1 pt-2" tabIndex="0">
                        <button id="dropdown-btn1"></button>
                        <ul
                          className="dropdown-content1"
                          id="dropdown-content1"
                        ></ul>
                      </div>
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <nav className="navbar navbar-expand-lg">
          <div className="container">
            <Link
              to="/"
              className="navbar-brand d-block d-sm-block d-md-none d-lg-none"
            >
              <h5 className="brand-name">
                <img src={`${process.env.PUBLIC_URL}/Triclomate.png`} alt=""   height="100" width="100" /> 
              </h5>
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon">
                <i className="fa-solid fa-bars"></i>
              </span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link to={`/product/${customerId}`} className="nav-link">
                    New Arrivals <small>#Fresh</small>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="#" className="nav-link">
                    Categories
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to={`/product/${customerId}`} className="nav-link">
                    Products
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="#" className="nav-link">
                    Trending{" "}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Navbar;
