import axios from "axios";

const CUSTOMER_SINGUP_REST_API = "http://35.154.130.93:8080/api/v1/customers/save";
const CUSTOMER_DETAILS_BYID_REST_API = "http://35.154.130.93:8080/api/v1/customers/details/{customerId}";
const CUSTOMER_DETAILS_REST_API =  "http://35.154.130.93:8080/api/v1/customers/details";
const CUSTOMER_DETAILS_UPDATE_REST_API = "http://35.154.130.93:8080/api/v1/customers/update/{customerId}";
const CUSTOMER_LOGIN_REST_API = "http://35.154.130.93:8080/api/v1/customers/login";

class CustomerAPI{
  getCustomerSingUp(formData) {
    return axios.post(CUSTOMER_SINGUP_REST_API, formData);
  }

  updateCustomerDetails(formData, customerId) {
    const apiUrl = CUSTOMER_DETAILS_UPDATE_REST_API.replace("{customerId}", customerId);
    return axios.post(apiUrl, formData);
  }

  getCustomerrDetails(customerId) {
    const apiUrl = CUSTOMER_DETAILS_BYID_REST_API.replace("{customerId}", customerId);
    return axios.get(apiUrl);
  }

  getCustomerDetailsList() {
    return axios.get(CUSTOMER_DETAILS_REST_API);
  }
  getCustomerLogin(formData) {
    return axios.post(CUSTOMER_LOGIN_REST_API,formData);
  }
}
export default new CustomerAPI();
