import React, { useState, useEffect } from "react";
import axios from "axios";
import AdminNavbar from "./AdminNavbar";
import { Link, useHistory, useParams } from "react-router-dom";
import "./admin.css";

export const UpdateProducts = () => {
  // const navigate = useNavigate();
  const history = useHistory();
  const [show, setShow] = useState(true);
  const { productId } = useParams();
  const [product, setProduct] = useState({
    productId: "",
    productName: "",
    productPrice: "",
    productImg: null,
    packSize: "",
    productInfo: "",
    mainPrice: "",
    offer: "",
    deliveryCharge: "",
    quantity: "",
  });

  useEffect(() => {
    fetchProductDetails(); // Fetch the fetchProductDetails details on component mount
  }, []);

  const fetchProductDetails = async () => {
    try {
      const response = await axios.get(
        `http://35.154.130.93:8080/api/v1/products/details/${productId}`
      );
      const productData = response.data;
      setProduct({
        productId: productData.productId,
        productName: productData.productName,
        productPrice: productData.productPrice,
        productImg: productData.productImg,
        packSize: productData.packSize,
        productInfo: productData.productInfo,
        mainPrice: productData.mainPrice,
        offer: productData.offer,
        deliveryCharge: productData.deliveryCharge,
        quantity: productData.quantity,
      });
    } catch (error) {
      console.log(error);
    }
  };

  // const handleChange = (event) => {
  //   const { name, value, type, checked } = event.target;
  //   setProduct((prevData) => ({
  //     ...prevData,
  //     [name]: type === 'checkbox' ? checked : value,
  //   }));
  // };

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === "file") {
      const reader = new FileReader();

      reader.onload = (event) => {
        setProduct((prevProduct) => ({
          ...prevProduct,
          [name]: event.target.result,
        }));
      };

      if (files.length > 0) {
        reader.readAsDataURL(files[0]);
      }
    } else {
      setProduct((prevProduct) => ({
        ...prevProduct,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        `http://35.154.130.93:8080/api/v1/products/update/${productId}`,
        product,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Products update successful!", response.data);
      history.push(`/product-management`);
    } catch (error) {
      console.error("Products update failed:", error);
    }
  };

  return (
    <>
      <AdminNavbar />
      <main className={show ? "space-toggle" : null}>
        <header className={`header ${show ? "space-toggle" : null}`}>
          <div className="header-toggle" onClick={() => setShow(!show)}>
            <i className={`fas fa-bars ${show ? "fas fa-bars" : null}`}></i>
          </div>
        </header>

        <aside className={`sidebar ${show ? "show" : null}`}>
          <nav className="nav">
            <div>
              <Link to="#" className="nav-logo">
                <span className="nav-logo-name">Menu</span>
              </Link>

              <div className="nav-list">
                <Link
                  to="/Admindashboard"
                  className="nav-linksidebar"
                >
                  <i className="fas fa-tachometer-alt nav-link-icon"></i>
                  <span className="nav-link-name">Dashboard</span>
                </Link>
                <Link to="/customer-management" className="nav-linksidebar">
                  <i class="bi bi-person-bounding-box"></i>
                  <span className="nav-link-name">Customer Management</span>
                </Link>
                <Link to="/product-management" className="nav-linksidebar active-bar">
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Product Management</span>
                </Link>
                <Link to="/total-order" className="nav-linksidebar">
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Total Orders</span>
                </Link>  
                <Link to="/totalAddToCart" className="nav-linksidebar">
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Total AddToCart</span>
                </Link> 
                <Link to="/totalShippingOrder" className="nav-linksidebar">
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Total Shipping Orders</span>
                </Link> 
                <Link to="/TotalOrderDelivery" className="nav-linksidebar">
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Total Order Delivery</span>
                </Link>
                <hr />
                <Link className="nav-logo">
                  <span className="nav-logo-name">Other</span>
                </Link>
                <Link to="#" className="nav-linksidebar">
                  <i class="bi bi-gear"></i>
                  <span className="nav-link-name">Settings</span>
                </Link>
              </div>
            </div>
          </nav>
        </aside>

        <div className="home-container mt-5">
          <div className="container-fluid pt-1 pb-3">
            <div className="row add-customerrow">
              <div className="col-lg-8">
                <form onSubmit={handleSubmit}>
                  <div className="add-customerbox">
                    <h5>Update a customer</h5>
                    <div className="row mt-4">
                      <div className="mb-3 col-lg-6">
                        <label for="exampleInputEmail1" className="form-label">
                          Product Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Enter your Product name"
                          name="productName"
                          value={product.productName}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div class="mb-3 col-lg-6">
                        <label for="exampleInputEmail1" className="form-label">
                          Product Price
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Enter your product Price"
                          name="productPrice"
                          value={product.productPrice}
                          onChange={handleChange}
                        />
                      </div>

                      <div class="mb-3 col-lg-6">
                        <label for="exampleInputEmail1" className="form-label">
                          Product Quantity
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Enter your Product Quantity"
                          name="quantity"
                          value={product.quantity}
                          onChange={handleChange}
                        />
                      </div>

                      <div class="mb-3 col-lg-6">
                        <label for="exampleInputEmail1" className="form-label">
                          Packing Size
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Enter pack Size "
                          name="packSize"
                          value={product.packSize}
                          onChange={handleChange}
                        />
                      </div>

                      <div class="mb-3 col-lg-6">
                        <label for="exampleInputEmail1" class="form-label">
                          Product Details
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Enter productInfo"
                          name="productInfo"
                          value={product.productInfo}
                          onChange={handleChange}
                        />
                      </div>

                      <div class="mb-3 col-lg-6">
                        <label for="exampleInputEmail1" class="form-label">
                          Main Price
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Enter main Price"
                          name="mainPrice"
                          value={product.mainPrice}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="mb-3 col-lg-6">
                        <label for="exampleInputEmail1" className="form-label">
                          Product Offer
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Enter offer"
                          name="offer"
                          value={product.offer}
                          onChange={handleChange}
                        />
                      </div>

                      <div class="mb-3 col-lg-6">
                        <label for="exampleInputEmail1" class="form-label">
                          Delivery Option
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Enter delivery Charge"
                          name="deliveryCharge"
                          value={product.deliveryCharge}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="mb-3 col-lg-6">
                        <label for="exampleInputEmail1" className="form-label">
                          Product Image
                        </label>
                        <input
                          type="file"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Enter product Img"
                          accept="image/*"
                          name="productImg"
                          onChange={handleChange}
                        />
                        {product.productImg && (
                          <img
                            src={`data:image/jpeg;base64,${product.productImg}`}
                            alt="Product Preview"
                            style={{
                              width: "150px",
                              height: "150px",
                              marginTop: "10px",
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="add-customerbtn pt-4">
                      <button type="submit">Update Product</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default UpdateProducts;
