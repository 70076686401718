import React, { useState, useEffect } from "react";
import { Link ,useHistory,useParams} from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./style.css";
import ProductsAPI from '../services/ProductsAPI';

import CustomerApi from '../services/CustomerAPI';

export const Products = () => {
  
  // const navigate = useNavigate();
  const history = useHistory();
  const [productData, setProductData] = useState([]);
  const [loading, setLoading] = useState(true);
  
  const [customerDetails, setCustomerDetails] = useState([]);
  
  const { customerId } = useParams();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await ProductsAPI.getProductDetailsList();
      const responseData = response.data;

      if (Array.isArray(responseData)) {
        setProductData(responseData);
      } else {
        console.error("API did not return an array:", responseData);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCardClick = (customerId, productId) => {
    // Use the navigate hook to navigate to the specified URL
    history.push(`/singleProductDetails/${customerId}/${productId}`);
  };

  useEffect(() => {
    console.log("Current customerId:", customerId);

    if (customerId && typeof customerId === "string" && customerId.trim() !== "") {
      const fetchCustomerDetails = async () => {
        try {
          const response = await CustomerApi.getCustomerrDetails(customerId);
          setCustomerDetails(response.data);
        } catch (error) {
          console.error("Error fetching product details:", error);
        }
      };

      fetchCustomerDetails();
    } else {
      console.error("ProductId is undefined or invalid");
      // Handle this case as needed, e.g., redirect or display an error message
    }
  }, [customerId]);

  return (
    <>
      <div className="col-lg-9">
        <center className="Product-heading mb-3">
          <h6>Products</h6>
        </center>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <div className="row">
            {productData.map((product) => (
              <div className="col-lg-4" key={product.productId}>
                <div className="item">
                  <div className="card"  onClick={() => handleCardClick(customerDetails.customerId, product.productId)}>
                    <figure className="snip1205">
                      <img
                        src={`data:image/jpeg;base64,${product.productImg}`} // Assuming it's a JPEG image
                        alt={product.productImgName}
                        style={{ width: "396px", height: "396px" }}
                        className="first-image"
                      />
                      <i className="bi bi-eye"></i>
                      {/* <Link to={`/singleProductDetails/${product.productId}`}></Link> */}
                    </figure>
                    <div className="card-body">
                      <div>
                        <h6>{product.productName}</h6>
                        <h6>Packing Size : {product.packSize}</h6>
                      </div>
                      <div>
                        <h5>
                          {" "}
                          <b>&#x20B9; {product.productPrice}</b>
                        <h6>Offer : {product.offer}</h6>
                        </h5>
                      </div>
                    </div>
                    <div className="add-cartbtn">
                      <button type="submit">
                        <i className="bi bi-handbag"></i> Add to Cart
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}


{/* static */}

<div className="row">
              <div className="col-lg-4">
                <div className="item">
                  <div className="card">
                    <figure className="snip1205">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/cleaner.png`} // Assuming it's a JPEG image
                        alt=""
                        style={{ width: "396px", height: "396px" }}
                        className="first-image"
                      />
                      <i className="bi bi-eye"></i>
                    </figure>
                    <div className="card-body">
                      <div>
                        <h6>{}</h6>
                        <h6>Packing Size : 500ml</h6>
                      </div>
                      <div>
                      <h6>{}</h6>
                        <h6><b>&#x20B9; 89</b></h6>
                      </div>
                    </div>
                    <div className="add-cartbtn">
                      <button type="submit">
                        <i className="bi bi-handbag"></i> Add to Cart
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="item">
                  <div className="card">
                    <figure className="snip1205">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/cleaner1.png`} // Assuming it's a JPEG image
                        alt=""
                        style={{ width: "396px", height: "396px" }}
                        className="first-image"
                      />
                      <i className="bi bi-eye"></i>
                    </figure>
                    <div className="card-body">
                      <div>
                        <h6>{}</h6>
                        <h6>Packing Size : 250ml</h6>
                      </div>
                      <div>
                      <h6>{}</h6>
                        <h6><b>&#x20B9; 39</b></h6>
                      </div>
                    </div>
                    <div className="add-cartbtn">
                      <button type="submit">
                        <i className="bi bi-handbag"></i> Add to Cart
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="item">
                  <div className="card">
                    <figure className="snip1205">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/cleaner1.png`} // Assuming it's a JPEG image
                        alt=""
                        style={{ width: "396px", height: "396px" }}
                        className="first-image"
                      />
                      <i className="bi bi-eye"></i>
                    </figure>
                    <div className="card-body">
                      <div>
                        <h6>{}</h6>
                        <h6>Packing Size : 500ml</h6>
                      </div>
                      <div>
                      <h6>{}</h6>
                        <h6><b>&#x20B9; 89</b></h6>
                      </div>
                    </div>
                    <div className="add-cartbtn">
                      <button type="submit">
                        <i className="bi bi-handbag"></i> Add to Cart
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="item">
                  <div className="card">
                    <figure className="snip1205">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/cleaner2.png`} // Assuming it's a JPEG image
                        alt=""
                        style={{ width: "396px", height: "396px" }}
                        className="first-image"
                      />
                      <i className="bi bi-eye"></i>
                    </figure>
                    <div className="card-body">
                      <div>
                        <h6>{}</h6>
                        <h6>Packing Size : {}</h6>
                      </div>
                      <div>
                      <h6>{}</h6>
                        <h6><b>&#x20B9; </b></h6>
                      </div>
                    </div>
                    <div className="add-cartbtn">
                      <button type="submit">
                        <i className="bi bi-handbag"></i> Add to Cart
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="item">
                  <div className="card">
                    <figure className="snip1205">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/cleaner3.png`} // Assuming it's a JPEG image
                        alt=""
                        style={{ width: "396px", height: "396px" }}
                        className="first-image"
                      />
                      <i className="bi bi-eye"></i>
                    </figure>
                    <div className="card-body">
                      <div>
                        <h6>{}</h6>
                        <h6>Packing Size : 1000ml</h6>
                      </div>
                      <div>
                      <h6>{}</h6>
                        <h6><b>&#x20B9; 125</b></h6>
                      </div>
                    </div>
                    <div className="add-cartbtn">
                      <button type="submit">
                        <i className="bi bi-handbag"></i> Add to Cart
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="item">
                  <div className="card">
                    <figure className="snip1205">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/cleaner4.png`} // Assuming it's a JPEG image
                        alt=""
                        style={{ width: "396px", height: "396px" }}
                        className="first-image"
                      />
                      <i className="bi bi-eye"></i>
                    </figure>
                    <div className="card-body">
                      <div>
                        <h6>{}</h6>
                        <h6>Packing Size : 500ml</h6>
                      </div>
                      <div>
                      <h6>{}</h6>
                        <h6><b>&#x20B9; 125</b></h6>
                      </div>
                    </div>
                    <div className="add-cartbtn">
                      <button type="submit">
                        <i className="bi bi-handbag"></i> Add to Cart
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="item">
                  <div className="card">
                    <figure className="snip1205">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/cleaner5.png`} // Assuming it's a JPEG image
                        alt=""
                        style={{ width: "396px", height: "396px" }}
                        className="first-image"
                      />
                      <i className="bi bi-eye"></i>
                    </figure>
                    <div className="card-body">
                      <div>
                        <h6>{}</h6>
                        <h6>Packing Size : 1000ml</h6>
                      </div>
                      <div>
                      <h6>{}</h6>
                        <h6><b>&#x20B9; 79</b></h6>
                      </div>
                    </div>
                    <div className="add-cartbtn">
                      <button type="submit">
                        <i className="bi bi-handbag"></i> Add to Cart
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="item">
                  <div className="card">
                    <figure className="snip1205">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/cleaner5.png`} // Assuming it's a JPEG image
                        alt=""
                        style={{ width: "396px", height: "396px" }}
                        className="first-image"
                      />
                      <i className="bi bi-eye"></i>
                    </figure>
                    <div className="card-body">
                      <div>
                        <h6>{}</h6>
                        <h6>Packing Size : 5000ml</h6>
                      </div>
                      <div>
                      <h6>{}</h6>
                        <h6><b>&#x20B9; 249</b></h6>
                      </div>
                    </div>
                    <div className="add-cartbtn">
                      <button type="submit">
                        <i className="bi bi-handbag"></i> Add to Cart
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="item">
                  <div className="card">
                    <figure className="snip1205">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/cleaner6.png`} // Assuming it's a JPEG image
                        alt=""
                        style={{ width: "396px", height: "396px" }}
                        className="first-image"
                      />
                      <i className="bi bi-eye"></i>
                    </figure>
                    <div className="card-body">
                      <div>
                        <h6>{}</h6>
                        <h6>Packing Size : 300ml</h6>
                      </div>
                      <div>
                      <h6>{}</h6>
                        <h6><b>&#x20B9; 229</b></h6>
                      </div>
                    </div>
                    <div className="add-cartbtn">
                      <button type="submit">
                        <i className="bi bi-handbag"></i> Add to Cart
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="item">
                  <div className="card">
                    <figure className="snip1205">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/cleaner7.png`} // Assuming it's a JPEG image
                        alt=""
                        style={{ width: "396px", height: "396px" }}
                        className="first-image"
                      />
                      <i className="bi bi-eye"></i>
                    </figure>
                    <div className="card-body">
                      <div>
                        <h6>{}</h6>
                        <h6>Packing Size : 250ml</h6>
                      </div>
                      <div>
                      <h6>{}</h6>
                        <h6><b>&#x20B9; 349</b></h6>
                      </div>
                    </div>
                    <div className="add-cartbtn">
                      <button type="submit">
                        <i className="bi bi-handbag"></i> Add to Cart
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="item">
                  <div className="card">
                    <figure className="snip1205">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/cleaner8.png`} // Assuming it's a JPEG image
                        alt=""
                        style={{ width: "396px", height: "396px" }}
                        className="first-image"
                      />
                      <i className="bi bi-eye"></i>
                    </figure>
                    <div className="card-body">
                      <div>
                        <h6>{}</h6>
                        <h6>Packing Size : 1975ml</h6>
                      </div>
                      <div>
                      <h6>{}</h6>
                        <h6><b>&#x20B9; 293</b></h6>
                      </div>
                    </div>
                    <div className="add-cartbtn">
                      <button type="submit">
                        <i className="bi bi-handbag"></i> Add to Cart
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="item">
                  <div className="card">
                    <figure className="snip1205">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/cleaner9.png`} // Assuming it's a JPEG image
                        alt=""
                        style={{ width: "396px", height: "396px" }}
                        className="first-image"
                      />
                      <i className="bi bi-eye"></i>
                    </figure>
                    <div className="card-body">
                      <div>
                        <h6>{}</h6>
                        <h6>Packing Size : {}</h6>
                      </div>
                      <div>
                        <h5>
                          {" "}
                          <b>&#x20B9; {}</b>
                        <h6>Offer : {}</h6>
                        </h5>
                      </div>
                    </div>
                    <div className="add-cartbtn">
                      <button type="submit">
                        <i className="bi bi-handbag"></i> Add to Cart
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="item">
                  <div className="card">
                    <figure className="snip1205">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/cleaner10.png`} // Assuming it's a JPEG image
                        alt=""
                        style={{ width: "396px", height: "396px" }}
                        className="first-image"
                      />
                      <i className="bi bi-eye"></i>
                    </figure>
                    <div className="card-body">
                      <div>
                        <h6>{}</h6>
                        <h6>Packing Size : 1750ml</h6>
                      </div>
                      <div>
                      <h6>{}</h6>
                        <h6><b>&#x20B9; 378</b></h6>
                      </div>
                    </div>
                    <div className="add-cartbtn">
                      <button type="submit">
                        <i className="bi bi-handbag"></i> Add to Cart
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="item">
                  <div className="card">
                    <figure className="snip1205">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/cleaner11.png`} // Assuming it's a JPEG image
                        alt=""
                        style={{ width: "396px", height: "396px" }}
                        className="first-image"
                      />
                      <i className="bi bi-eye"></i>
                    </figure>
                    <div className="card-body">
                      <div>
                        <h6>{}</h6>
                        <h6>Packing Size : 1975ml</h6>
                      </div>
                      <div>
                      <h6>{}</h6>
                        <h6><b>&#x20B9; 257</b></h6>
                      </div>
                    </div>
                    <div className="add-cartbtn">
                      <button type="submit">
                        <i className="bi bi-handbag"></i> Add to Cart
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="item">
                  <div className="card">
                    <figure className="snip1205">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/cleaner11.png`} // Assuming it's a JPEG image
                        alt=""
                        style={{ width: "396px", height: "396px" }}
                        className="first-image"
                      />
                      <i className="bi bi-eye"></i>
                    </figure>
                    <div className="card-body">
                      <div>
                        <h6>{}</h6>
                        <h6>Packing Size : 1975ml</h6>
                      </div>
                      <div>
                      <h6>{}</h6>
                        <h6><b>&#x20B9; 293</b></h6>
                      </div>
                    </div>
                    <div className="add-cartbtn">
                      <button type="submit">
                        <i className="bi bi-handbag"></i> Add to Cart
                      </button>
                    </div>
                  </div>
                </div>
              </div>
          </div>
      </div>
    </>
  );
};

export default Products;