import React from 'react';
import './App.css';
// import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import {Home} from './components/Home';
import AdminLogin from './admin/AdminLogin';
import UpdateProducts from './adminProducts/UpdateProducts';
import UpdateCustomer from './adminProducts/UpdateCustomer';
import AdminSidedashboard from './adminProducts/AdminSidedashboard';
import Customer from './adminProducts/customer';
import ProductDetails from './adminProducts/ProductDetails';
import AddProduct from './adminProducts/AddProduct';
import CustomerRegister from './components/CustomerRegister';
import CustomerLogin from './components/CustomerLogin';
import SingleProductDetails from './components/SingleProductDetails';
import MyOrder from './components/MyOrder';
import MyCart from './components/MyCart';
import OrderDetails from './components/OrderDetails';
import CheckOut from './components/CheckOut';
import UserProfile from './components/UserProfile';
import PrivacyPolicy from './components/PrivacyPolicy';
import RefundPolicy from './components/RefundPolicy';
import TermUse from './components/TermUse';
import TermsCondition from './components/TermsCondition';
import TotalOreder from './adminProducts/TotalOreder';
import ViewByIdOder from './adminProducts/ViewByIdOder';
import TotalAddToCartOrder from './adminProducts/TotalAddToCartOrder';
import ViewByIdAddToCart from './adminProducts/ViewByIdAddToCart';
import TotalOrderShipping from './adminProducts/TotalOrderShipping';
import UpdateOrderShipping from './adminProducts/UpdateOrderShipping';
import CustomerWaysOrder from './adminProducts/CustomerWaysOrder';
import CustomerWaysAddToCart from './adminProducts/CustomerWaysAddToCart';
import TotalOrderDelivery from './adminProducts/TotalOrderDelivery';
import ViewOrderDelivery from './adminProducts/ViewOrderDelivery';
import CustomerViewOderDelivery from './adminProducts/CustomerViewOderDelivery';
import ViewOrderShipping from './adminProducts/ViewOrderShipping';
import CustomerViewOderShipping from './adminProducts/CustomerViewOderShipping';

const App = () => {
  return (
    <Router>
      <Switch>
        
        <Route exact path="/" component={ Home } />
        <Route path="/product/:customerId" component={ Home } />
        <Route path="/customerLogin" component={CustomerLogin} />
        <Route path="/customerRegister" component={CustomerRegister} />
        <Route path="/singleProductDetails/:customerId/:productId" component={SingleProductDetails} />
        <Route path="/myOrder/:customerId" component={MyOrder} />
        <Route path="/myCart/:customerId" component={MyCart} />
        <Route path="/orderDetails/:customerId/:orderId" component={OrderDetails} />
        <Route path="/checkOut/:customerId/:productId" component={CheckOut} />
        <Route path="/userProfile/:customerId/" component={UserProfile} />

        {/* <Route exact path="/" component={ Home } />        
        <Route path="/:customerId" component={Home} />
        <Route path="/customerRegister" component={CustomerRegister} />
        <Route path="/customerLogin" component={CustomerLogin} />
        <Route path="/singleProductDetails/:customerId/:productId" element={<SingleProductDetails />} />
        <Route path="/myOrder/:customerId" element={<MyOrder />} />
        <Route path="/myCart/:customerId" element={<MyCart />} />
        <Route path="/orderDetails/:customerId/:orderId" element={<OrderDetails />} />
        <Route path="/checkOut/:customerId/:productId" element={<CheckOut />} />
  <Route path="/userProfile/:customerId/" element={<UserProfile />} />*/}

        <Route path="/AdminLogin" component={AdminLogin} />
        <Route path="/Admindashboard" component={AdminSidedashboard} />
        <Route path="/customer-management" component={Customer} />
        <Route path="/product-management" component={ProductDetails} />
        <Route path="/add-product" component={AddProduct} />

        <Route path="/UpdateProducts/:productId" component={UpdateProducts} />
        <Route path="/updatecustomer/:customerId" component={UpdateCustomer} />
        <Route path="/privacyPolicy" component={PrivacyPolicy} />
        <Route path="/refundPolicy" component={RefundPolicy} />
        <Route path="/termUse" component={TermUse} />
        <Route path="/termsCondition" component={TermsCondition} />

        <Route path="/total-order" component={TotalOreder} />
        <Route path="/oderView/:orderId" component={ViewByIdOder} />
        <Route path="/totalAddToCart" component={TotalAddToCartOrder} />
        <Route path="/viewAddToCart/:addToCartId" component={ViewByIdAddToCart} />
        <Route path="/totalShippingOrder" component={TotalOrderShipping} />
        <Route path="/updateOrderShipping/:orderId" component={UpdateOrderShipping} />
        <Route path="/CustomerWaysOrder/:customerId" component={CustomerWaysOrder} />
        <Route path="/CustomerWaysAddToCart/:customerId" component={CustomerWaysAddToCart} />
        <Route path="/TotalOrderDelivery" component={TotalOrderDelivery} />
        <Route path="/viewOrderDelivery/:orderId" component={ViewOrderDelivery} />
        <Route path="/CustomerViewOderDelivery/:customerId" component={CustomerViewOderDelivery} />
        <Route path="/ViewOrderShipping/:orderId" component={ViewOrderShipping} />
        <Route path="/CustomerViewOderShipping/:customerId" component={CustomerViewOderShipping} />

        
        {/*<Route path="/AdminLogin" element={<AdminLogin />} />
        <Route path="/Admindashboard" element={<AdminSidedashboard />} />
        <Route path="/customer-management" element={<Customer />} />
        <Route path="/product-management" element={<ProductDetails />} />
        <Route path="/add-product" element={<AddProduct />} />

        <Route path="/UpdateProducts/:productId" element={<UpdateProducts />} />
        <Route path="/updatecustomer/:customerId" element={<UpdateCustomer />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/refundPolicy" element={<RefundPolicy />} />
        <Route path="/termUse" element={<TermUse />} />
        <Route path="/termsCondition" element={<TermsCondition />} /> */}
        </Switch>
      </Router>
  );
}

export default App;

