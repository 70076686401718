// import React from 'react';
// import ReactDOM from 'react-dom';
// import { BrowserRouter as Router } from 'react-router-dom';

// // import { BrowserRouter } from 'react-router-dom';
// import App from './App';
// import { AuthProvider } from "./services/AuthContext";
// import reportWebVitals from './reportWebVitals';


// ReactDOM.render(

//   <BrowserRouter>
//   <App />
// </BrowserRouter>,
  // <Router>
  // <AuthProvider>
    // <App />    
  //   </AuthProvider>
  // </Router>,
  // document.getElementById('root')
// );


// reportWebVitals();


import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById("root")
);
reportWebVitals();