import React, { useState } from "react";
import axios from "axios";
import "./admin.css";
import Adminnav from "./adminnav";
import AdminNavbar from "./AdminNavbar";
import { Link, useHistory } from "react-router-dom";

const AddProduct = () => {
  // const navigate = useNavigate();
  const history = useHistory();
  const [show, setShow] = useState(true);
  const [productName, setProductName] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [productImg, setProductImg] = useState(null);
  const [packSize, setPackSize] = useState("");
  const [productInfo, setProductInfo] = useState("");
  const [mainPrice, setMainPrice] = useState("");
  const [offer, setOffer] = useState("");
  const [deliveryCharge, setDeliveryCharge] = useState("");
  const [quantity, setQuantity] = useState("");

  const handleProductImageChange = (e) => {
    setProductImg(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("productName", productName);
    formData.append("productPrice", productPrice);
    formData.append("productImg", productImg);
    formData.append("mainPrice", mainPrice);
    formData.append("packSize", packSize);
    formData.append("productInfo", productInfo);
    formData.append("offer", offer);
    formData.append("deliveryCharge", deliveryCharge);
    formData.append("quantity",quantity);
    try {
      await axios.post(
        "http://35.154.130.93:8080/api/v1/products/saves",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Product added successfully");
      history.push(`/product-management`);
      // You can redirect or show a success message here
    } catch (error) {
      console.error("Error adding product:", error);
      // Handle error and show an error message
    }
  };

  return (
    <>
    
    <AdminNavbar />
      {/* <Adminnav /> */}

      <main className={show ? "space-toggle" : null}>
        <header className={`header ${show ? "space-toggle" : null}`}>
          <div className="header-toggle" onClick={() => setShow(!show)}>
          </div>
        </header>

        <aside className={`sidebar ${show ? "show" : null}`}>
          <nav className="nav">
            <div>
              <Link to="#" className="nav-logo">
                <span className="nav-logo-name">Menu</span>
              </Link>

              <div className="nav-list">
                <Link
                  to="/Admindashboard"
                  className="nav-linksidebar"
                >
                  <i className="fas fa-tachometer-alt nav-link-icon"></i>
                  <span className="nav-link-name">Dashboard</span>
                </Link>
                <Link to="/customer-management" className="nav-linksidebar">
                  <i class="bi bi-person-bounding-box"></i>
                  <span className="nav-link-name">Customer Management</span>
                </Link>
                <Link to="/product-management" className="nav-linksidebar active-bar">
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Product Management</span>
                </Link>
                <Link to="/total-order" className="nav-linksidebar">
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Total Orders</span>
                </Link>  
                <Link to="/totalAddToCart" className="nav-linksidebar">
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Total AddToCart</span>
                </Link> 
                <Link to="/totalShippingOrder" className="nav-linksidebar">
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Total Shipping Orders</span>
                </Link> 
                <Link to="/TotalOrderDelivery" className="nav-linksidebar">
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Total Order Delivery</span>
                </Link>
                <hr />
                <Link className="nav-logo">
                  <span className="nav-logo-name">Other</span>
                </Link>
                <Link to="#" className="nav-linksidebar">
                  <i class="bi bi-gear"></i>
                  <span className="nav-link-name">Settings</span>
                </Link>
              </div>
            </div>
          </nav>
        </aside>

        <div className="home-container">
          <div className="container-fluid pt-1 pb-3">
            <div className="row add-customerrow">
              <div className="col-lg-8">
                <form onSubmit={handleSubmit}>
                  <div className="add-customerbox">
                    <h5>Add a product</h5>
                    <div className="row mt-4">
                      <div className="mb-3 col-lg-6">
                        <label for="exampleInputEmail1" className="form-label">
                        Product Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Enter your product name"
                          name="productName"
                          value={productName}
                              onChange={(e) => setProductName(e.target.value)}
                          required
                        />
                      </div>
                      <div class="mb-3 col-lg-6">
                        <label for="exampleInputEmail1" className="form-label">
                        Product Price
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Enter your product Price"
                          name="productPrice"
                          value={productPrice}
                          onChange={(e) => setProductPrice(e.target.value)}
                          required
                        />
                      </div>
                      <div class="mb-3 col-lg-6">
                        <label for="exampleInputEmail1" className="form-label">
                        Product Quantity
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Enter your Product Quantity"
                          value={quantity}
                          onChange={(e) => setQuantity(e.target.value)}
                          required
                        />
                      </div>
                      <div class="mb-3 col-lg-6">
                        <label for="exampleInputEmail1" className="form-label">
                        Packing Size
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Enter pack Size"
                          name="packSize"
                          value={packSize}
                          onChange={(e) => setPackSize(e.target.value)}
                          required
                        />
                      </div>
                      <div class="mb-3 col-lg-6">
                        <label for="exampleInputEmail1" class="form-label">
                        Product Details
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Enter productInfo"
                          name="productInfo"
                          value={productInfo}
                          onChange={(e) =>
                            setProductInfo(e.target.value)}
                          required
                        />
                      </div>
                      <div class="mb-3 col-lg-6">
                        <label for="exampleInputEmail1" class="form-label">
                        Main Price
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Enter main Price"
                          name="mainPrice"
                          value={mainPrice}
                          onChange={(e) => setMainPrice(e.target.value)}
                          required
                        />
                      </div>

                      <div className="mb-3 col-lg-6">
                        <label for="exampleInputEmail1" className="form-label">
                        Product Offer
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Enter offer"
                          name="offer"
                          value={offer}
                          onChange={(e) => setOffer(e.target.value)}
                        />
                      </div>

                      <div class="mb-3 col-lg-6">
                        <label for="exampleInputEmail1" class="form-label">
                        Delivery Option
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Enter delivery Charge"
                          name="deliveryCharge"
                          value={deliveryCharge}
                          onChange={(e) => setDeliveryCharge(e.target.value)}
                          required
                        />
                      </div>
                      <div className="mb-3 col-lg-6">
                        <label for="exampleInputEmail1" className="form-label">
                        Product Image
                        </label>
                        <input
                          type="file"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Enter product Img"
                          name="productImg"
                          // value={productImg}
                          accept="image/*"
                          onChange={handleProductImageChange}
                        />
                      </div>
                    </div>
                    <div className="add-customerbtn pt-4">
                      <button type="submit">Add Product</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default AddProduct;
