import React, { useEffect, useState } from "react";
// import { Link, useHistory } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./style.css";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useParams } from "react-router-dom";
import CustomerAPI from "../services/CustomerAPI";

const UserProfile = () => {
  // const navigate = useNavigate();
  
  // const history = useHistory();
  const { customerId } = useParams();
  const [customerDetails, setCustomerDetails] = useState({
    customerId: "",
    firstName: "",
    number: "",
    email: "",
    lastName: "",
    houseNo: "",
    city: "",
    state: "",
    pinCode: "",
    areaAddress: "",
  });
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    if (
      customerId &&
      typeof customerId === "string" &&
      customerId.trim() !== ""
    ) {
      const fetchCustomerDetails = async () => {
        try {
          const response = await CustomerAPI.getCustomerrDetails(customerId);
          setCustomerDetails(response.data);
        } catch (error) {
          console.error("Error fetching customer details:", error);
        }
      };

      fetchCustomerDetails();
    } else {
      console.error("customerId is undefined or invalid");
    }
  }, [customerId]);

  const handleEditClick = () => {
    setIsEditMode(true);
  };

  const handleSaveClick = async () => {
    try {
      // Call the API to update customer details
      await CustomerAPI.updateCustomerDetails(customerDetails, customerId);

      // Exit edit mode
      setIsEditMode(false);
    } catch (error) {
      console.error("Error updating customer details:", error);
      // Handle the error as needed
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCustomerDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };
  return (
    <>
      <Navbar />

      <div className="container pt-4 pb-4">
        <div className="row" style={{ justifyContent: "center" }}>
          <div className="col-lg-8">
            <h5 className="profile-heading">My Profile</h5>
            <div className="row profile-box mt-3">
              <div className="col-lg-4">
                <div className="profile-info">
                  <div className="me-3">
                    <img src="/user.jpg" width="60" height="60" alt="" />
                  </div>
                  <div>
                    <h4>{customerDetails.firstName}</h4>
                    <h6>{customerDetails.email}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8 pt-4">
            <div className="row profile-box">
              <div className="col-lg-4">
                <div className="profile-info">
                  <h5>Personal Information</h5>
                </div>
              </div>
              <div className="col-lg-6"></div>
              <div className="col-lg-2">
                <div className="profile-edit-btn">
                  {!isEditMode ? (
                    <button onClick={handleEditClick}>
                      Edit <i className="bi bi-pencil"></i>
                    </button>
                  ) : (
                    <button onClick={handleSaveClick}>
                      Save <i className="bi bi-check"></i>
                    </button>
                  )}
                </div>
              </div>
              <div className="col-lg-5">
                <form className="profile-form">
                  <h5>First Name</h5>
                  {!isEditMode ? (
                    <h6>{customerDetails.firstName}</h6>
                  ) : (
                    <input
                      type="text"
                      name="firstName"
                      value={customerDetails.firstName}
                      onChange={handleChange}
                    />
                  )}
                </form>
              </div>
              <div className="col-lg-5">
                <form className="profile-form">
                  <h5>Last Name</h5>
                  {!isEditMode ? (
                    <h6>{customerDetails.lastName}</h6>
                  ) : (
                    <input
                      type="text"
                      name="lastName"
                      value={customerDetails.lastName}
                      onChange={handleChange}
                    />
                  )}
                </form>
              </div>
              <div className="col-lg-2"></div>
              <div className="col-lg-5">
                <form className="profile-form">
                  <h5>Email Address</h5>
                  {!isEditMode ? (
                    <h6>{customerDetails.email}</h6>
                  ) : (
                    <input
                      type="text"
                      name="email"
                      value={customerDetails.email}
                      onChange={handleChange}
                    />
                  )}
                </form>
              </div>
              <div className="col-lg-5">
                <form className="profile-form">
                  <h5>Phone Number</h5>
                  {!isEditMode ? (
                    <h6>{customerDetails.number}</h6>
                  ) : (
                    <input
                      type="text"
                      name="number"
                      value={customerDetails.number}
                      onChange={handleChange}
                    />
                  )}
                </form>
              </div>
              <div className="col-lg-2"></div>
              <div className="col-lg-5">
                <form className="profile-form">
                  <h5>House No.</h5>
                  {!isEditMode ? (
                    <h6>{customerDetails.houseNo}</h6>
                  ) : (
                    <input
                      type="text"
                      name="houseNo"
                      value={customerDetails.houseNo}
                      onChange={handleChange}
                    />
                  )}
                </form>
              </div>
              <div className="col-lg-5">
                <form className="profile-form">
                  <h5>City</h5>
                  {!isEditMode ? (
                    <h6>{customerDetails.city}</h6>
                  ) : (
                    <input
                      type="text"
                      name="city"
                      value={customerDetails.city}
                      onChange={handleChange}
                    />
                  )}
                </form>
              </div>
              <div className="col-lg-2"></div>
              <div className="col-lg-5">
                <form className="profile-form">
                  <h5>State</h5>
                  {!isEditMode ? (
                    <h6>{customerDetails.state}</h6>
                  ) : (
                    <input
                      type="text"
                      name="state"
                      value={customerDetails.state}
                      onChange={handleChange}
                    />
                  )}
                </form>
              </div>
              <div className="col-lg-5">
                <form className="profile-form">
                  <h5>Pin Code</h5>
                  {!isEditMode ? (
                    <h6>{customerDetails.pinCode}</h6>
                  ) : (
                    <input
                      type="text"
                      name="pinCode"
                      value={customerDetails.pinCode}
                      onChange={handleChange}
                    />
                  )}
                </form>
              </div>
              <div className="col-lg-2"></div>
              <div className="col-lg-5">
                <form className="profile-form">
                  <h5>Area Address</h5>
                  {!isEditMode ? (
                    <h6>{customerDetails.areaAddress}</h6>
                  ) : (
                    <input
                      type="text"
                      name="areaAddress"
                      value={customerDetails.areaAddress}
                      onChange={handleChange}
                    />
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default UserProfile;
