import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import axios from "axios";
import CustomerApi from '../services/CustomerAPI';

// const CustomerLogin = () => {
//   // const navigate = useNavigate();
//   const history = useHistory();
//   const [formData, setFormData] = useState({
//     number: "",
//     password: "",
//   });

//   const [errors, setErrors] = useState({});

//   const handleChange = (event) => {
//     const { name, value } = event.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     // Simple validation - check if fields are not empty
//     const newErrors = {};
//     if (!formData.number.trim()) {
//       newErrors.number = "Email/Phone Number is required";
//     }
//     if (!formData.password.trim()) {
//       newErrors.password = "Password is required";
//     }

//     if (Object.keys(newErrors).length > 0) {
//       // If there are validation errors, update the errors state and return early
//       setErrors(newErrors);
//       return;
//     }

//     try {
//       const response = await CustomerApi.getCustomerLogin(formData);
//       console.log("Login successful! Token:", response.data.token);
      
//       if (response.status === 200) {
//         history.push(`/product/${response.data.customerId}`);
//       }
//     } catch (error) {
//       console.error("Login failed:", error);
//     }
//   };


const CustomerLogin = () => {
  const history = useHistory();
  const [formData, setFormData] = useState({
    number: "",
    password: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Simple validation - check if fields are not empty
    const newErrors = {};
    if (!formData.number.trim()) {
      newErrors.number = "Phone Number is required";
    }
    if (!formData.password.trim()) {
      newErrors.password = "Password is required";
    }

    if (Object.keys(newErrors).length > 0) {
      // If there are validation errors, update the errors state and return early
      setErrors(newErrors);
      return;
    }

    try {
      const response = await axios.post(
        "https://18.212.31.182:8080/api/v1/customers/login",
        formData
      );
      console.log("Login successful! Token:", response.data.token);

      //   if (response.data.status === 'success') {
      // history.push(`/staffprofile/${response.data.staffId}`);
      // }
      if (response.status === 200) {
        history.push(`/product/${response.data.customerId}`);
      }
    } catch (error) {
      console.error("Login failed:", error);

      // Handle specific error responses from the API
      if (error.response && error.response.data) {
        const errorResponse = error.response.data;
        if (errorResponse.error === "Unauthorized") {
          setErrors({ invalidCredentials: errorResponse.message });
        } else {
          setErrors({
            genericError: "An error occurred. Please try again later.",
          });
        }
      }
    }
  };

  return (
    <>
      <Navbar />
      <div className="container pt-4 pb-4">
        <div className="row login-row">
          <div className="col-lg-5">
            <div className="login-img">
              <div>
                <img src={`${process.env.PUBLIC_URL}/login-img.png`} alt="" />
                <h5>
                  Instant order <br />& Buy
                </h5>
                <h6>
                  Triclomate will receive your order <br /> and be able to reply to
                  you once. <br /> You place an order{" "}
                </h6>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="login-form">
              <div className="d-flex" style={{ alignItems: 'center' }}>
                <img src={`${process.env.PUBLIC_URL}/extra-logo.png`} alt="" />
                <h6>Triclomate</h6>
              </div>
              <h4>Login</h4>
              <p className="mb-4">
                Login to make an order, access your orders, special offers, and{" "}
                <br /> more!{" "}
              </p>
              <div className="login-line"></div>
              <form className="pt-4" onSubmit={handleSubmit}>
                <div className="mb-3 email-form">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Phone Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter phone number"
                    name="number"
                    value={formData.number}
                    onChange={handleChange}
                  />
                  {errors.number && (
                    <div className="error-message">{errors.number}</div>
                  )}
                </div>
                <div className="mb-4 pass-form">
                  <label htmlFor="exampleInputPassword1" className="form-label">
                  Password
                    </label>
                    <input
                      type="password"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Enter your password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                    />
                    {errors.password && (
                      <div className="error-message">{errors.password}</div>
                    )}
                </div>
                <div className="mb-3 form-check login-check">
                  <input
                     class="form-check-input"
                     type="checkbox"
                     value=""
                     id="form1Example3"
                     checked
                  />
                  
                  <label className="form-check-label" htmlFor="exampleCheck1">
                    I've read and agree with the{" "}
                    <a href="">Terms of services</a>, <br />
                    <a href="">Privacy Policy</a> and{" "}
                    <a href=""> Return and Refund Policy</a>
                  </label>
                </div>

                {/* <div className="login-btn">
                  <button type="submit"> Login </button>
                </div> */}
                 <div class="d-grid">
                    <button type="submit" class="btn btn-secondary btn-lg btn-rounded order-yellow-btn">Sign in</button>
                </div>
                <br></br><center>
                {errors.invalidCredentials && (
                  <div className="error-message">
                    {errors.invalidCredentials}
                  </div>
                )}
                {errors.genericError && (
                  <div className="error-message">{errors.genericError}</div>
                )}
                </center>
              </form>
              <small>
                Don't have an account?{" "}
                <Link to="/customerRegister" className="switch-form">
                  Register
                </Link>
              </small>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CustomerLogin;
