import axios from "axios";

// const PRODUCTORDER_ADD_REST_API = "http://localhost:8085/api/v1/productOrder/save";
const PRODUCTORDER_DETAILS_REST_API =  "http://35.154.130.93:8080/api/v1/products/details/{productId}";
// const PRODUCTORDER__DETAILS_UPDATE_REST_API = "http://localhost:8080/cctv/api/v1/productOrder/update";
const PRODUCTS_DETAILS_LIST_REST_API = "http://35.154.130.93:8080/api/v1/products/details";

class ProductsAPI {
//   getProductOrderAdd() {
//     return axios.get(PRODUCTORDER_ADD_REST_API);
//   }

//   getProductOrderUpdate() {
//     return axios.get(PRODUCTORDER__DETAILS_UPDATE_REST_API);
//   }

getProductDetails(productId) {
  const apiUrl = PRODUCTORDER_DETAILS_REST_API.replace("{productId}", productId);
  return axios.get(apiUrl);
}

  getProductDetailsList() {
    return axios.get(PRODUCTS_DETAILS_LIST_REST_API);
  }
}
export default new ProductsAPI();
