import React from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./style.css";
import Footer from "./Footer";
import Navbar from "./Navbar";

const TermsCondition = () => {
  return (
    <>
      <Navbar />

      <div className="container pt-4 mb-4">
        <div className="row">
          <div className="col-lg-3">
            <div className="side-nav-policy">
              <Link to="/privacyPolicy">
                <p>
                  <img src="images/privacy.png" alt="" /> &nbsp; Privacy Policy
                </p>
              </Link>
              <Link to="/termUse">
                <p>
                  <img src="images/term.png" alt="" /> &nbsp; Terms of use
                </p>
              </Link>
              <Link to="/termsCondition">
                <p className="active">
                  <img src="images/condition.png" alt="" /> &nbsp; Terms and
                  conditions
                </p>
              </Link>
              <Link to="/refundPolicy">
                <p>
                  <img src="images/ref.png" alt="" /> &nbsp; Refund Policy
                </p>
              </Link>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="terms-page">
            <h4>Terms & Condition</h4>
            <h5>1. Acceptance of Terms</h5>
            <p>
            By accessing and using [www.triclomate.com], you agree to be bound by these terms and conditions. If you do not agree with any part of these terms, please do not use our website.
            </p>
           <h5>2. User Accounts</h5>
           <p>
            <b>a.</b> You may be required to create an account to access certain features of our website. You are responsible for maintaining the confidentiality of your account information.
           </p>
           <p>
            <b>b.</b> You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.
           </p>
           <h5>3. Orders and Payments</h5>
           <p>
            <b>a.</b> By placing an order on our website, you are making an offer to purchase the products at the specified price.
           </p>
           <p>
            <b>b.</b> We reserve the right to refuse or cancel any order for any reason, including but not limited to availability, errors in the description or price of the product, or an error in your order.
           </p>
           <p>
            <b>c.</b> Payment must be made in full before the order is processed and shipped.
           </p>
           <h5>4. Shipping and Delivery</h5>
           <p>
            <b>a.</b> We will make reasonable efforts to deliver products within the estimated timeframe. However, we are not responsible for delays beyond our control.
           </p>
           <p>
            <b>b.</b> The risk of loss and title for products pass to you upon our delivery to the carrier.
           </p>
           <h5>5. Returns and Refunds</h5>
           <p>Our return policy is provide details of your return policy, including conditions for returns and the refund process.</p>
           <h5>6. Intellectual Property</h5>
           <p>All content on this website, including text, graphics, logos, images, and software, is the property of Botanical Aura pvt ltd and is protected by intellectual property laws.</p>
          <h5>7. Privacy Policy</h5>
          <p>Your use of our website is also governed by our Privacy Policy, which can be found provide link to your privacy policy.</p>
          <h5>8. Limitation of Liability</h5>
          <p> <b>"Botanical Aura Pvt Ltd" </b>will not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly.</p>
          <h5>9. Governing Law</h5>
          <p>These terms and conditions are governed by and construed in accordance with the laws of your jurisdiction.</p>
          <h5>Changes to Terms</h5>
          <p>We reserve the right to modify or replace these terms at any time. Please check back periodically for changes.</p>
          <p>By using <b>"www.triclomate.com"</b>, you agree to these terms and conditions. If you have any questions or concerns, please contact us at.</p>
          <p>
            <b>Botanical Aura Pvt Ltd<br/>
            Shivpur,Varanasi 221003<br/>
            www.info.triclomate@gmail.com, 7007802676<br/>
            </b>
          </p>
          </div>
        </div>
      </div>
    </div>

    <Footer />
    </>
  );
};

export default TermsCondition;