import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./style.css";
import Footer from "./Footer";
import Navbar from "./Navbar";
import ProductsAPI from "../services/ProductsAPI";
import AddToCartAPI from "../services/AddToCartAPI";

import CustomerApi from "../services/CustomerAPI";
import { useParams } from "react-router-dom";

// import PropTypes from "prop-types";

const SingleProductDetails = () => {
  // const navigate = useNavigate();
  const history = useHistory();
  const { customerId, productId } = useParams();

  const [productDetails, setProductDetails] = useState([]);
  const [customerDetails, setCustomerDetails] = useState([]);

  const [quantity, setQuantity] = useState(1); // Default quantity is 1
  const [formData, setFormData] = useState({
    productPrice: "",
    productId: "",
    customerId: "",
    productName: "",
    quantity: "",
    productOrderId: "",
  });

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleAddToCart = async () => {
    try {
      const formData = {
        productName: productDetails.productName,
        productPrice: productDetails.productPrice,
        productId: productDetails.productId,
        customerId: customerDetails.customerId,
        productOrderId: "1234512", // Replace with the appropriate value
        quantity: quantity,
      };
      const response = await AddToCartAPI.getAddToCartSave(formData);
      console.log("AddToCart saved successfully:", response.data);
      history.push(`/myCart/${customerId}`);
      // Optionally, you can perform additional actions after a successful save.
    } catch (error) {
      console.error("Failed to save AddToCart:", error);
      // Handle errors or provide user feedback.
    }
  };

  useEffect(() => {
    console.log("Current productId:", productId);

    if (productId && typeof productId === "string" && productId.trim() !== "") {
      const fetchProductDetails = async () => {
        try {
          const response = await ProductsAPI.getProductDetails(productId);
          setProductDetails(response.data);
        } catch (error) {
          console.error("Error fetching product details:", error);
        }
      };

      fetchProductDetails();
    } else {
      console.error("ProductId is undefined or invalid");
      // Handle this case as needed, e.g., redirect or display an error message
    }
  }, [productId]);

  useEffect(() => {
    console.log("Current customerId:", customerId);

    if (
      customerId &&
      typeof customerId === "string" &&
      customerId.trim() !== ""
    ) {
      const fetchCustomerDetails = async () => {
        try {
          const response = await CustomerApi.getCustomerrDetails(customerId);
          setCustomerDetails(response.data);
        } catch (error) {
          console.error("Error fetching product details:", error);
        }
      };

      fetchCustomerDetails();
    } else {
      console.error("ProductId is undefined or invalid");
      // Handle this case as needed, e.g., redirect or display an error message
    }
  }, [customerId]);

  const handleIncrement = () => {
    setQuantity(quantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  if (!productDetails) {
    return <div>Loading...</div>; // You can customize this to show a loading indicator
  }
  return (
    <>
      <Navbar />
      <div className="container mt-2 mb-3">
        <div className="row no-gutters" style={{ justifyContent: "center" }}>
          <div className="col-md-5 pr-2">
            <ul id="lightSlider">
              <li data-thumb={productDetails.productImg}>
                <img
                  src={`data:image/jpeg;base64,${productDetails.productImg}`}
                  alt={productDetails.productImgName}
                  style={{ width: "396px", height: "396px" }}
                  className="first-image"
                />
              </li>
            </ul>
            <br></br>
            <br></br>
            <div class="rating-box">
              <a href="" class="rating-start">
                <i class="bi bi-star"></i> 4.8
              </a>
              <a href="" class="review-count" id="review">
                <i class="bi bi-chat-left-dots"></i> 67 Reviews
              </a>
              <h5>
                <span>93% </span>of buyers have recommended this.
              </h5>
            </div>
          </div>
          <div className="col-md-1"></div>
          <div className="col-md-5">
            <div className="details-card">
              <div className="detailed-info">
                {productDetails && (
                  <>
                    <h5>{productDetails.productName}</h5>
                    <p>{productDetails.designer}</p>
                    <hr />
                  </>
                )}
              </div>
              <div className="price-review">
                <div className="price-details">
                  <h4>
                    &#x20B9;{" "}
                    {productDetails && productDetails.productPrice * quantity}
                  </h4>
                  <h4>{productDetails.offer} Off</h4>
                  <h6>&#x20B9;{productDetails.mainPrice}</h6>
                </div>

                {/* Add and Subtract Buttons */}
                <div className="quantity-buttons">
                  <button className="increment-btn" onClick={handleIncrement}>
                    {" "}
                    +{" "}
                  </button>
                  <button className="decrement-btn" onClick={handleDecrement}>
                    {" "}
                    -{" "}
                  </button>
                </div>

                {/* Display Quantity */}
                <div className="quantity-display">
                  <p>Quantity: {quantity}</p>
                </div>
              </div>
              <div className="product-details">
                <hr />
                <h5>Packing Size</h5>
                {productDetails && (
                  <h6 className="product-info">{productDetails.packSize}</h6>
                )}
              </div>
              <div className="product-details">
                <hr />
                <h5>Product Details</h5>
                {productDetails && (
                  <h6 className="product-info">{productDetails.productInfo}</h6>
                )}
              </div>
              <div className="details-buttons pt-4">
                <Link
                  to={`/checkOut/${customerId}/${productId}?quantity=${quantity}`}
                  className="chat-btn"
                >
                  <i className="bi bi-product-order"></i> Order
                </Link>
                </div>
                <div>
                &nbsp;
                <form onSubmit={handleAddToCart}>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    name="productPrice"
                    value={productDetails.productPrice}
                    onChange={handleChange}
                    hidden
                  />
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputPassword1"
                    name="productId"
                    value={productDetails.productId}
                    onChange={handleChange}
                    hidden
                  />
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    name="customerId"
                    value={customerDetails.customerId}
                    onChange={handleChange}
                    hidden
                  />
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputPassword1"
                    name="productName"
                    value={productDetails.productName}
                    onChange={handleChange}
                    hidden
                  />
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    name="quantity"
                    value={productDetails.quantity}
                    onChange={handleChange}
                    hidden
                  />
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    name="productOrderId"
                    value={productDetails.productOrderId}
                    onChange={handleChange}
                    hidden
                  />
                  {/* <Link to={`/myCart/${customerId}`} className="cart-btn" type="button"> */}
                  <button type="button" class="btn btn-secondary btn-lg btn-rounded custom-yellow-btn" onClick={handleAddToCart}>
                    <i className="bi bi-handbag"></i> Add to Cart
                  </button>             
                  
                  {/* </Link> */}
                </form>
              </div>
              <div class="delivery-box mt-4">
                <div class="free-deliverybox d-flex">
                  <i class="bi bi-truck"></i>
                  <div class="px-2">
                    <h5>Free Delivery</h5>
                    <h6>Enter your Postal code for Delivery Availability</h6>
                  </div>
                </div>
                <hr />
                <div class="return-deliverybox d-flex">
                  <i class="bi bi-handbag"></i>
                  <div class="px-2">
                    <h5>Return Delivery</h5>
                    <h6>Free 30 days Delivery Return.</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container pt-4">
        <center className="Product-heading mb-5">
          <h6>Ratings & Reviews</h6>
        </center>
        <div className="row">
          <div className="col-lg-10">
            <div className="first-review">
              <h5>
                Aditya Agarwal <i class="bi bi-star-fill"></i>
                <i class="bi bi-star-fill"></i> <i class="bi bi-star-fill"></i>
                <i class="bi bi-star-fill"></i> <i class="bi bi-star-fill"></i>
              </h5>
              <small>12-11-2023</small>
              <p>
                Great seller. Very responsive to my instructions and delivered
                perfectly what I wanted. Very impressed! perfectly what I wanted
                Professional and fast!
              </p>
              <hr />
              <h5>
              Ankur Srivastava <i class="bi bi-star-fill"></i>
                <i class="bi bi-star-fill"></i> <i class="bi bi-star-fill"></i>
                <i class="bi bi-star-fill"></i> 
              </h5>
              <small>12-11-2023</small>
              <p>
              I bought the all in 1 surface cleaner it was really effective against the toughest stains and spots
              </p>
              <hr /><h5>
                Aman Singh <i class="bi bi-star-fill"></i>
                <i class="bi bi-star-fill"></i> <i class="bi bi-star-fill"></i>
                <i class="bi bi-star-fill"></i> 
              </h5>
              <small>12-11-2023</small>
              <p>
              Nice product and very effective, I'll suggest you to give a try
              </p> 
              <hr /><h5>
                Shubham Kr. Patel<i class="bi bi-star-fill"></i>
                <i class="bi bi-star-fill"></i> <i class="bi bi-star-fill"></i>
                <i class="bi bi-star-fill"></i> <i class="bi bi-star-fill"></i>
              </h5>
              <small>12-11-2023</small>
              <p>
              It is best & excellent. It brings really innovative & fruitful notions.
              </p>
              <hr /><h5>
                Pooja Singh <i class="bi bi-star-fill"></i>
                <i class="bi bi-star-fill"></i> <i class="bi bi-star-fill"></i>
                <i class="bi bi-star-fill"></i> <i class="bi bi-star-fill"></i>
              </h5>
              <small>13-11-2023</small>
              <p>
              I brought the Triclomate white floor cleaner and the results were amazingly superb . Tiles are shining like never before
              </p>
              <hr /><h5>
                Ajit Singh <i class="bi bi-star-fill"></i>
                <i class="bi bi-star-fill"></i> <i class="bi bi-star-fill"></i>
                <i class="bi bi-star-fill"></i>
              </h5>
              <small>13-11-2023</small>
              <p>
              All those product very good and very helpful...👌🏻
              </p>
              <hr />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
{
  /* SingleProductDetails.propTypes = {
  productId: PropTypes.string.isRequired,
}; */
}

export default SingleProductDetails;
