import React, { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

const AdminLogin = () => {
  const history = useHistory();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Simple validation - check if fields are not empty
    const newErrors = {};
    if (!formData.email.trim()) {
      newErrors.email = "Email/Phone Number is required";
    }
    if (!formData.password.trim()) {
      newErrors.password = "Password is required";
    }

    if (Object.keys(newErrors).length > 0) {
      // If there are validation errors, update the errors state and return early
      setErrors(newErrors);
      return;
    }

    try {
      const response = await axios.post(
        "http://35.154.130.93:8080/api/v1/admin/login",
        formData
      );
      console.log("Login successful! Token:", response.data.token);
      history.push(`/Admindashboard`);
    } catch (error) {
      console.error("Login failed:", error);

      // Handle specific error responses from the API
      if (error.response && error.response.data) {
        const errorResponse = error.response.data;
        if (errorResponse.error === "Unauthorized") {
          setErrors({ invalidCredentials: errorResponse.message });
        } else {
          setErrors({
            genericError: "An error occurred. Please try again later.",
          });
        }
      }
    }
  };

  return (
    <>
      <section class="vh-100">
        <div class="container py-5 h-100">
          <div class="row d-flex align-items-center justify-content-between h-100">
            <div class="col-md-8 col-lg-7 col-xl-6">
              <img
                src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.svg"
                class="img-fluid"
              />
            </div>
            <div class="col-md-7 col-lg-5 col-xl-5 offset-xl-1 py-5 border border-dark rounded">
              <form onSubmit={handleSubmit}>
                <h2>Admin Login</h2>
                {/* <!-- Email input --> */}
                <div class="form-outline my-4">
                  <label class="form-label" for="form1Example13">
                    Phone Number/Email Id
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="form1Example13"
                    class="form-control form-control-lg"
                    aria-describedby="emailHelp"
                    placeholder="Enter email or phone number"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  {errors.email && (
                    <div className="error-message">{errors.email}</div>
                  )}
                </div>
                <div class="form-outline mb-4">
                  <label class="form-label" for="form1Example23">
                  Password
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="exampleInputPassword1"
                      placeholder="Password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                    />
                    {errors.password && (
                      <div className="error-message">{errors.password}</div>
                    )}
                  </div>

                <div class="d-flex justify-content-around align-items-center mb-4">
                  {/* <!-- Checkbox --> */}
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="form1Example3"
                      checked
                    />
                    <label class="form-check-label" for="form1Example3">
                      {" "}
                      Remember me{" "}
                    </label>
                  </div>
                </div>

                {/* <!-- Submit button --> */}
                <div class="d-grid">
                  <button
                    type="submit"
                    class="btn btn-primary btn-lg btn-rounded"
                  >
                    Sign in
                  </button>
                </div>
                <br></br><center>
                  {errors.invalidCredentials && (
                  <div className="error-message">
                    {errors.invalidCredentials}
                  </div>
                )}
                {errors.genericError && (
                  <div className="error-message">{errors.genericError}</div>
                )}
                </center>

            </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AdminLogin;
