import React, { useState, useEffect } from "react";
import "./admin.css";
import { Link } from "react-router-dom";
import AdminNavbar from "./AdminNavbar";

const AdminSidedashboard = () => {
  const [show, setShow] = useState(true);
  
  const [totalProduct, setTotalProduct] = useState(null);
  const [totalCustomer, setTotalCustomer] = useState(0);
  const [totalOrder, setTotalOrder] = useState(null);
  const [totalDelivery, setTotalDelivery] = useState(null);
  const [totalPendingDelivery, setTotalPendingDelivery] = useState(null);
  const [totalAddToCard, setTotalAddToCard] = useState(null);
  

  useEffect(() => {
    const fetchTotalProduct = async () => {
      try {
        const response = await fetch("http://35.154.130.93:8080/api/v1/products/count");
        const data = await response.json();
        setTotalProduct(data); // assuming the API response is an object with the count
      } catch (error) {
        console.error("Error fetching total Product count:", error);
      }
    };

    fetchTotalProduct();
  }, []); 

  useEffect(() => {
    const fetchTotalCustomer = async () => {
      try {
        const response = await fetch(`http://35.154.130.93:8080/api/v1/customers/count`);
        const data = await response.json();
        setTotalCustomer(data); // Extracting the count from the data object
      } catch (error) {
        console.error("Error fetching total Customer count:", error);
      }
    };

    fetchTotalCustomer();
  }, []);

  useEffect(() => {
    const fetchTotalOrder = async () => {
      try {
        const response = await fetch("http://35.154.130.93:8080/api/v1/productOrder/count");
        const data = await response.json();
        setTotalOrder(data); // assuming the API response is an object with the count
      } catch (error) {
        console.error("Error fetching total camera count:", error);
      }
    };

    fetchTotalOrder();
  }, []); 

  useEffect(() => {
    const fetchTotalDelivery = async () => {
      try {
        const response = await fetch("http://35.154.130.93:8080/api/v1/productOrder/completed/count");
        const data = await response.json();
        setTotalDelivery(data); // assuming the API response is an object with the count
      } catch (error) {
        console.error("Error fetching total camera count:", error);
      }
    };

    fetchTotalDelivery();
  }, []);
  
  useEffect(() => {
    const fetchTotalPendingDelivery = async () => {
      try {
        const response = await fetch("http://35.154.130.93:8080/api/v1/productOrder/pending/count");
        const data = await response.json();
        setTotalPendingDelivery(data); // assuming the API response is an object with the count
      } catch (error) {
        console.error("Error fetching total camera count:", error);
      }
    };

    fetchTotalPendingDelivery();
  }, []); 

  useEffect(() => {
    const fetchTotalAddToCard = async () => {
      try {
        const response = await fetch("http://35.154.130.93:8080/api/v1/addToCart/count");
        const data = await response.json();
        setTotalAddToCard(data); // assuming the API response is an object with the count
      } catch (error) {
        console.error("Error fetching total camera count:", error);
      }
    };

    fetchTotalAddToCard();
  }, []); 

  return (
    <>
    <AdminNavbar />
      {/* <Adminnav /> */}
      <main className={show ? "space-toggle" : null}>
        <header className={`header ${show ? "space-toggle" : null}`}>
          <div className="header-toggle" onClick={() => setShow(!show)}>
            <i className={`fas fa-bars ${show ? "fas fa-bars" : null}`}></i>
          </div>
        </header>

        <aside className={`sidebar ${show ? "show" : null}`}>
          <nav className="nav">
            <div>
              <Link to="#" className="nav-logo">
                <span className="nav-logo-name">Menu</span>
              </Link>

              <div className="nav-list">
                <Link
                  to="/Admindashboard"
                  className="nav-linksidebar active-bar"
                >
                  <i className="fas fa-tachometer-alt nav-link-icon"></i>
                  <span className="nav-link-name">Dashboard</span>
                </Link>
                <Link to="/customer-management" className="nav-linksidebar">
                  <i class="bi bi-person-bounding-box"></i>
                  <span className="nav-link-name">Customer Management</span>
                </Link>
                <Link to="/product-management" className="nav-linksidebar">
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Product Management</span>
                </Link>
                <Link to="/total-order" className="nav-linksidebar">
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Total Orders</span>
                </Link>  
                <Link to="/totalAddToCart" className="nav-linksidebar">
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Total AddToCart</span>
                </Link> 
                <Link to="/totalShippingOrder" className="nav-linksidebar">
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Total Shipping Orders</span>
                </Link> 
                <Link to="/TotalOrderDelivery" className="nav-linksidebar">
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Total Order Delivery</span>
                </Link>
                <hr />
                <Link className="nav-logo">
                  <span className="nav-logo-name">Other</span>
                </Link>
                <Link to="#" className="nav-linksidebar">
                  <i class="bi bi-gear"></i>
                  <span className="nav-link-name">Settings</span>
                </Link>
              </div>
            </div>
          </nav>
        </aside>
        <div className="home-container mt-5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-9">
                <div className="row dashbox">
                  <div className="col-lg-3">
                <Link to="/customer-management">
                    <div className="firstdashbox">
                      <div className="">
                        
                        <h5>
                          Total Customer<br /> <span>{totalCustomer}</span>
                        </h5>
                      </div>
                      <div className="">
                        <i class="fa-solid fa-users"></i>
                      </div>
                    </div>
                  </Link>
                  </div>
                   <div className="col-lg-3">
                <Link to="/product-management">
                    <div className="firstdashbox">
                      <div className="">
                        <h5>
                          Total Product <br /> <span>{totalProduct}</span>
                        </h5>
                      </div>
                      <div className="">
                        <i class="fa-solid fa-users"></i>
                      </div>
                    </div>
                    </Link>
                  </div>
                <div className="col-lg-3">
                <Link to="/total-order">
                    <div className="firstdashbox">
                      <div className="">
                        <h5>
                          Total Order <br /> <span>{totalOrder}</span>
                        </h5>
                      </div>
                      <div className="">
                        <i class="fa-solid fa-users"></i>
                      </div>
                    </div>
                    </Link>
                  </div>
                   <div className="col-lg-3">
                <Link to="/TotalOrderDelivery">
                    <div className="firstdashbox">
                      <div className="">
                        <h5>
                          Total Delivery<br /> <span>{totalDelivery}</span>
                        </h5>
                      </div>
                      <div className="">
                        <i class="fa-solid fa-users"></i>
                      </div>
                    </div>
                    </Link>
                  </div>
                  <div className="col-lg-3">
                <Link to="/totalShippingOrder">
                    <div className="firstdashbox">
                      <div className="">
                        <h5>
                          Total Pending Delivery<br /> <span>{totalPendingDelivery}</span>
                        </h5>
                      </div>
                      <div className="">
                        <i class="fa-solid fa-users"></i>
                      </div>
                    </div>
                    </Link>
                  </div>
                  <div className="col-lg-3">
                <Link to="/totalAddToCart">
                    <div className="firstdashbox">
                      <div className="">
                        <h5>
                          Total AddToCart<br /> <span>{totalAddToCard}</span>
                        </h5>
                      </div>
                      <div className="">
                        <i class="fa-solid fa-users"></i>
                      </div>
                    </div> 
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="activity-box">
                  <h5>Total Activity</h5>
                  <p>Here is the list of all details data.</p>
                  <div className="activity-notification">
                    <div className="activity-innerbox">
                      <h6>Customer logged in {totalCustomer}</h6>
                    </div>
                    <hr className="notify-hr" />
                    <div className="activity-innerbox">
                      <h6>Total Product lists  {totalProduct}</h6>
                    </div>
                    <hr className="notify-hr" />
                    <div className="activity-innerbox">
                      <h6>Total Order lists  {totalOrder}</h6>
                    </div>
                    <hr className="notify-hr" />
                    <div className="activity-innerbox">
                      <h6>Total Delivery Order Completed {totalDelivery}</h6>
                    </div>
                    <hr className="notify-hr" />
                    <div className="activity-innerbox">
                      <h6>Total Pending Delivery Order {totalPendingDelivery}</h6>
                    </div>
                    <hr className="notify-hr" />
                    <div className="activity-innerbox">
                      <h6>Total AddToCard  {totalAddToCard}</h6>
                    </div>
                    <hr className="notify-hr" />
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      </>
    );
  };

  export default AdminSidedashboard;
