import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { Link, useHistory, useParams } from "react-router-dom";
import AdminNavbar from "./AdminNavbar";
import "./admin.css";
import TotalOrderAPI from "../services/AdminAPI/TotalOrderAPI";

const ViewByIdOder = () => {
  // const navigate = useNavigate();
//   const history = useHistory();
  const [show, setShow] = useState(true);
  const { orderId } = useParams();
  const [order, setOrder] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await TotalOrderAPI.getProductOrdersDetails(orderId);
        const responseData = response.data;
        console.log('Response Data:', responseData);
        setOrder(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching product order details:', error);
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, []); // Make sure to include any dependencies if needed

  if (loading) {
    return <p>Loading...</p>;
  }
  return (
    <>
      {/* <Navbar /> */}
      <AdminNavbar />
      <main className={show ? "space-toggle" : null}>
        <header className={`header ${show ? "space-toggle" : null}`}>
          <div className="header-toggle" onClick={() => setShow(!show)}>
            <i className={`fas fa-bars ${show ? "fas fa-bars" : null}`}></i>
          </div>
        </header>

        <aside className={`sidebar ${show ? "show" : null}`}>
          <nav className="nav">
            <div>
              <Link to="#" className="nav-logo">
                <span className="nav-logo-name">Menu</span>
              </Link>

              <div className="nav-list">
                <Link
                  to="/Admindashboard"
                  className="nav-linksidebar"
                >
                  <i className="fas fa-tachometer-alt nav-link-icon"></i>
                  <span className="nav-link-name">Dashboard</span>
                </Link>
                <Link to="/customer-management" className="nav-linksidebar">
                  <i class="bi bi-person-bounding-box"></i>
                  <span className="nav-link-name">Customer Management</span>
                </Link>
                <Link to="/product-management" className="nav-linksidebar">
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Product Management</span>
                </Link>
                <Link to="/total-order" className="nav-linksidebar active-bar">
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Total Orders</span>
                </Link>  
                <Link to="/totalAddToCart" className="nav-linksidebar">
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Total AddToCart</span>
                </Link> 
                <Link to="/totalShippingOrder" className="nav-linksidebar">
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Total Shipping Orders</span>
                </Link> 
                <Link to="/TotalOrderDelivery" className="nav-linksidebar">
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Total Order Delivery</span>
                </Link>
                <hr />
                <Link className="nav-logo">
                  <span className="nav-logo-name">Other</span>
                </Link>
                <Link to="#" className="nav-linksidebar">
                  <i class="bi bi-gear"></i>
                  <span className="nav-link-name">Settings</span>
                </Link>
              </div>
            </div>
          </nav>
        </aside>

        <div className="home-container mt-5">
          <div className="container-fluid pt-1 pb-3">
            <div className="row add-customerrow">
              <div className="col-lg-8">
                <form>
                  <div className="add-customerbox">
                    <h5>Product Oder Details</h5>
                    <Link to="/total-order">
                      <button type="submit">Back</button>
                    </Link>
                    <div className="row mt-4">
                      <div className="mb-3 col-lg-6">
                        <label for="exampleInputEmail1" className="form-label">
                          Order Id
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="orderId"
                          value={order.orderId}
                          readOnly
                        />
                      </div>
                      <div className="mb-3 col-lg-6">
                        <label for="exampleInputEmail1" className="form-label">
                          Product Order Id
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="productOrderId"
                          value={order.productOrderId} readOnly
                        />
                      </div>
                      <div class="mb-3 col-lg-6">
                        <label for="exampleInputEmail1" className="form-label">
                          Start Date
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="startDate"
                          value={order.startDate} readOnly
                        />
                      </div>
                      <div class="mb-3 col-lg-6">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                          End Date
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="endDate"
                          value={order.endDate} readOnly
                        />
                      </div>
                      <div className="mb-3 col-lg-6">
                        <label for="exampleInputEmail1" className="form-label">
                          Delivery Date
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="deliveryDate"
                          value={order.deliveryDate} readOnly
                        />
                      </div>
                      <div className="mb-3 col-lg-6">
                        <label for="exampleInputEmail1" className="form-label">
                          Order Total Price
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="orderTotalPrice"
                          value={order.orderTotalPrice} readOnly
                        />
                      </div>
                      <div className="mb-3 col-lg-6">
                        <label for="exampleInputEmail1" className="form-label">
                          Order Status
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="orderStatus"
                          value={order.orderStatus} readOnly
                        />
                      </div>
                      <div className="mb-3 col-lg-6">
                        <label for="exampleInputEmail1" className="form-label">
                          Return Status
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="returnStatus"
                          value={order.returnStatus} readOnly
                        />
                      </div>
                      <div className="mb-3 col-lg-6">
                        <label for="exampleInputEmail1" className="form-label">
                          Confirm Order
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="confirmOrder"
                          value={order.confirmOrder} readOnly
                        />
                      </div>
                      <div className="mb-3 col-lg-6">
                        <label
                          htmlFor="exampleInputPassword1"
                          className="form-label"
                        >
                          Pick-Up
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="pickUp"
                          value={order.pickUp} readOnly
                        />
                      </div>
                      <div className="mb-3 col-lg-6">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                          On-The-Way
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="onTheWay"
                          value={order.onTheWay} readOnly
                        />
                      </div>
                      <div className="mb-3 col-lg-6">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                          Payment Status
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="paymentStatus"
                          value={order.paymentStatus} readOnly
                        />
                      </div>
                      <div className="mb-3 col-lg-6">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                          Customer Id
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="customerId"
                          value={order.customerId} readOnly
                        />
                      </div>
                      <div className="mb-3 col-lg-6">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                          Product Id
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="productId"
                          value={order.productId} readOnly
                        />
                      </div>
                      <div className="mb-3 col-lg-6">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                          Quantity
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="quantity"
                          value={order.quantity} readOnly
                        />
                      </div>
                      <div className="mb-3 col-lg-6">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                          Order Date
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="orderAddDateTime"
                          value={order.orderAddDateTime} readOnly
                        />
                      </div>
                      <div className="mb-3 col-lg-6">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                          Product Price
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="productPrice"
                          value={order.productPrice} readOnly
                        />
                      </div>
                      <div className="mb-3 col-lg-6">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                          Product Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="productName"
                          value={order.productName} readOnly
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default ViewByIdOder;
