import axios from "axios";

const PRODUCTORDER_ADD_REST_API = "http://35.154.130.93:8080/api/v1/productOrder/save";
const PRODUCTORDER_DETAILS_REST_API =  "http://35.154.130.93:8080/api/v1/productOrder/details/{orderId}";
const PRODUCTORDER__DETAILS_UPDATE_REST_API = "http://35.154.130.93:8080/api/v1/productOrder/update";
const PRODUCTORDER_DETAILS_LIST_REST_API = "http://35.154.130.93:8080/api/v1/productOrder/details";
const PRODUCTORDER_CUSTOMER_DETAILS_LIST_REST_API = "http://35.154.130.93:8080/api/v1/productOrder/details/orderProductCustomerDetails/{customerId}";
const PRODUCTORDER_CUSTOMER_BY_ID_DETAILS_LIST_REST_API = "http://35.154.130.93:8080/api/v1/productOrder/details/CustomerId/{customerId}";


class ProductsOrderAPI {
  getProductOrderAdd(formData) {
    return axios.post(PRODUCTORDER_ADD_REST_API,formData);
  }

  getProductOrderUpdate() {
    return axios.get(PRODUCTORDER__DETAILS_UPDATE_REST_API);
  }

  getProductOrderCustomerDetails(customerId) {
    const apiUrl = PRODUCTORDER_CUSTOMER_DETAILS_LIST_REST_API.replace("{customerId}", customerId);
    return axios.get(apiUrl);
  }

  getProductOrderCustomerDetailsById(customerId) {
    const apiUrl = PRODUCTORDER_CUSTOMER_BY_ID_DETAILS_LIST_REST_API.replace("{customerId}", customerId);
    return axios.get(apiUrl);
  }

  getProductOrdersDetails(orderId) {
    const apiUrl = PRODUCTORDER_DETAILS_REST_API.replace("{orderId}", orderId);
    return axios.get(apiUrl);
  }


  getProductOrderDetailsList() {
    return axios.get(PRODUCTORDER_DETAILS_LIST_REST_API);
  }

}
export default new ProductsOrderAPI();
