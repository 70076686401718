import React, { useEffect, useState } from "react";
import { useParams ,Link} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./style.css";
import Navbar from "./Navbar";
import Footer from "./Footer";
import ProductsOrderAPI from "../services/ProductsOrderAPI";

const OrderDetails = () => {
  const { orderId } = useParams();
  const { customerId } = useParams();
  const [productOrderDetails, setProductOrderDetails] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log("Current orderId:", orderId);

    if (orderId && typeof orderId === "string" && orderId.trim() !== "") {
      const fetchProductOrderDetails = async () => {
        try {
          console.log("Calling getProductOrderDetails API...");
          const response = await ProductsOrderAPI.getProductOrderDetails(orderId);
          console.log('API Response:', response.data);

          if (response.data) {
            setProductOrderDetails(response.data);
            setLoading(false);
          } else {
            console.error("API response is empty or not as expected:", response.data);
          }
        } catch (error) {
          console.error("Error fetching product details:", error);
        }
      };

      fetchProductOrderDetails();
    } else {
      console.error("OrderId is undefined or invalid");
    }
  }, [orderId]);

  return (
    <>
      <Navbar />
      <div className="container pt-4 pb-4">
        <div className="row" style={{ justifyContent: "center" }}>
        <div className="col-lg-9">
            <div className="page-tab d-flex mb-4">
              <div className="me-3">
                <Link to={`/myCart/${customerId}`}>My Cart</Link>
              </div>
              <div>
                <Link to={`/myOrder/${customerId}`} className="below-line">
                  My Orders
                </Link>
              </div>
            </div>
            {loading && <p>Loading...</p>}
            {!loading && (
              <div className="outer-border-box">
                    <div className="track">
                      <div className="step active">
                        <span className="icon">
                          {" "}
                          <i className="fa fa-check"></i>{" "}
                        </span>
                        <span className="text">
                          Order confirmed <br />
                          <small>{productOrderDetails.ProductOrderDate}</small>
                        </span>
                      </div>
                      <div class="step active">
                        <span class="icon">
                          {" "}
                          <i class="fa fa-user"></i>{" "}
                        </span>
                        <span class="text">
                          Dispatched <br />
                          <small>{productOrderDetails.ProductOrderDate}</small>
                        </span>
                      </div>
                      <div class="step">
                        <span class="icon">
                          {" "}
                          <i class="fa fa-truck"></i>{" "}
                        </span>
                        <span class="text">
                          On the way <br />
                          <small>{productOrderDetails.ProductOrderDate}</small>
                        </span>
                      </div>
                      <div class="step">
                        <span class="icon">
                          {" "}
                          <i class="fa fa-box"></i>{" "}
                        </span>
                        <span class="text">
                          Ready for pickup <br />
                          <small>{productOrderDetails.ProductOrderDate}</small>
                        </span>
                      </div>
                    </div>
                    <div className="row my-orderbox pt-4">
                      <div className="col-lg-4">
                        <div className="order-basic-detail">
                          <div>
                            <img src="images/order1.png" alt="" />
                          </div>
                          <div>
                            <h6>
                              {productOrderDetails.productName} <br />
                              Map
                            </h6>
                            <span>QTY : {productOrderDetails.quantity}</span>
                            <h5>
                              &#x20B9;{productOrderDetails.orderTotalPrice}
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="order-status">
                          <h6>Status</h6>
                          <h5>{productOrderDetails.orderStatus}</h5>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="Delivery-status">
                          <h6>Delivery Expected by</h6>
                          <h5>{productOrderDetails.deliveryDate}</h5>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="order-basic-info">
                          <h6>
                            Order ID :{" "}
                            <span>{productOrderDetails.productOrderId}</span>
                          </h6>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="order-basic-info">
                          <h6>
                            Order Name :{" "}
                            <span>{productOrderDetails.productName}</span>
                          </h6>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="order-basic-info">
                          <h6>
                            Order placed date :{" "}
                            <span>{productOrderDetails.deliveryDate}</span>
                          </h6>
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <div className="order-basic-info">
                          <h6>
                            Order Status :
                            <span>{productOrderDetails.orderStatus} </span>
                          </h6>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="order-basic-info">
                          <h6>
                            Payment Status :{" "}
                            <span>{productOrderDetails.paymentStatus} </span>
                          </h6>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="order-basic-info">
                          <h6>
                            Personalized Text : <span>Happy Family</span>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
              )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default OrderDetails;
