import React, { useEffect, useState } from "react";
import "./admin.css";
import { Link, useHistory } from "react-router-dom";
import Adminnav from "./adminnav";
import ProductsAPI from '../services/ProductsAPI';
import axios from "axios";
import AdminNavbar from "./AdminNavbar";
import 'bootstrap-icons/font/bootstrap-icons.css';

const ProductDetails = () => {
  // const navigate = useNavigate();
  const history = useHistory();
  const [show, setShow] = useState(true);
  const [products, setProducts] = useState([]);
  
  useEffect(() => {
    const loadProducts = async () => {
      const productList = await fetchProducts();
      setProducts(productList);
    };

    loadProducts();
  }, []);
  
  // Function to fetch products from the backend
  const fetchProducts = async () => {
    try {
      const response = await axios.get('http://35.154.130.93:8080/api/v1/products/details'); // Replace with your actual API endpoint
      return response.data; // This should be an array of products
    } catch (error) {
      console.error('Error fetching products:', error);
      return [];
    }
  };

  const handleDelete = async (productId) => {
    try {
      await axios.get(
        `http://35.154.130.93:8080/api/v1/products/delete/${productId}`
      );
      setProducts(products.filter((product) => product.productId !== productId));
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdate = async (productId) => {
    try {
      // Redirect to the user details page with the productId as a parameter
      history.push(`/UpdateProducts/${productId}`);
    } catch (error) {
      console.log(error);
    }
  };

  const handleStatusChange = async (product, newStatus) => {
    try {
      const updatedProduct = { ...product, productStatus: newStatus };
      await axios.post(
        `http://35.154.130.93:8080/api/v1/products/update/${product.productId}`,
        updatedProduct,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      
      // fetchData(); // Refresh the client data
    } catch (error) {
      console.error("Error updating client status:", error);
    }
  };

  return (
    <>
      {/* <Adminnav /> */}
      <AdminNavbar />

      <main className={show ? "space-toggle" : null}>
        <header className={`header ${show ? "space-toggle" : null}`}>
          <div className="header-toggle" onClick={() => setShow(!show)}>
            <i className={`fas fa-bars ${show ? "fas fa-bars" : null}`}></i>
          </div>
        </header>

        <aside className={`sidebar ${show ? "show" : null}`}>
          <nav className="nav">
            <div>
              <Link to="#" className="nav-logo">
                <span className="nav-logo-name">Menu</span>
              </Link>

              <div className="nav-list">
                <Link
                  to="/Admindashboard"
                  className="nav-linksidebar"
                >
                  <i className="fas fa-tachometer-alt nav-link-icon"></i>
                  <span className="nav-link-name">Dashboard</span>
                </Link>
                <Link to="/customer-management" className="nav-linksidebar">
                  <i class="bi bi-person-bounding-box"></i>
                  <span className="nav-link-name">Customer Management</span>
                </Link>
                <Link to="/product-management" className="nav-linksidebar active-bar">
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Product Management</span>
                </Link>
                <Link to="/total-order" className="nav-linksidebar">
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Total Orders</span>
                </Link>  
                <Link to="/totalAddToCart" className="nav-linksidebar">
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Total AddToCart</span>
                </Link> 
                <Link to="/totalShippingOrder" className="nav-linksidebar">
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Total Shipping Orders</span>
                </Link> 
                <Link to="/TotalOrderDelivery" className="nav-linksidebar">
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Total Order Delivery</span>
                </Link>
                <hr />
                <Link className="nav-logo">
                  <span className="nav-logo-name">Other</span>
                </Link>
                <Link to="#" className="nav-linksidebar">
                  <i class="bi bi-gear"></i>
                  <span className="nav-link-name">Settings</span>
                </Link>
              </div>
            </div>
          </nav>
        </aside>

        <div className="home-container mt-5">
          <div className="container-fluid">
            <div className="row">
              <div class="row">
                <div class="table-box">
                  <div class="pt-2">
                    <div className="outer-filter">
                      <p>Product Details</p>
                      <div className="new-filter">
                        <div>
                          <input
                            class="search"
                            type="search"
                            placeholder="Search..."
                          />
                        </div>
                        <div>
                          <Link to="/add-product">
                            <button>
                              <i class="bi bi-person-plus-fill"></i> &nbsp; Add
                              Product
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <table>
                      <thead>
                        <tr>
                          <th>Product Id</th>
                          <th>Product Name</th>
                          <th>Quantity</th>
                          <th>Price</th>
                          <th>Pack Size</th>
                          <th>Offer</th>
                          <th>Main Price</th>
                          <th>Details</th>
                          <th>Delivery</th>
                          <th>Image</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
      {products.length > 0 ? (
        products.map((product) => (
          <tr key={product.productId} className="product">
            <td>{product.productId}</td>
            <td>{product.productName}</td>
            <td>{product.quantity}</td>
            <td>{product.productPrice}</td>
            <td>{product.packSize}</td>
            <td>{product.offer}</td>
            <td>{product.mainPrice}</td>
            <td>{product.productInfo}</td>
            <td>{product.deliveryCharge}</td>
            <td>
              <img
                src={`data:image/jpeg;base64,${product.productImg}`}
                alt={product.productImgName}
                style={{ width: '100px', height: '100px' }}
              />
            </td>
                              <td>
                                <i
                                  className="bi bi-pencil-square"
                                  onClick={() => handleUpdate(product.productId)}
                                ></i>
                                &nbsp;
                                <i
                                  className="bi bi-trash3"
                                  onClick={() => handleDelete(product.productId)}
                                ></i>
                                &nbsp;
                                {/* <i className="bi bi-eye"></i> */}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="10">No Products found.</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default ProductDetails;
