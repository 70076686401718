import axios from "axios";

const ADDTOCART_ADD_REST_API = "http://35.154.130.93:8080/api/v1/addToCart/save";
const ADDTOCART__DETAILS_UPDATE_REST_API = "http://35.154.130.93:8080/api/v1/addToCart/update/{addToCartId}";
const ADDTOCART_DELETE_REST_API = "http://35.154.130.93:8080/api/v1/addToCart/delete/{addToCartId}";
// const ADDTOCART_CUSTOMER_DETAILS_LIST_REST_API = "http://35.154.130.93:8080/api/v1/addToCart/details/addToCartProductCustomerDetails/{customerId}";
const ADDTOCART_CUSTOMER_DETAILS_LIST_REST_API = "http://35.154.130.93:8080/api/v1/addToCart/details/addToCartProductCustomerDetails/{customerId}";

const ADDTOCART_DETAILS_LIST_REST_API = "http://35.154.130.93:8080/api/v1/addToCart/details";
const ADDTOCART_DETAILS_SHOW_BY_ID_REST_API =  "http://35.154.130.93:8080/api/v1/addToCart/details/{addToCartId}";



class AddToCartAPI {
  getAddToCartSave(formData) {
    return axios.post(ADDTOCART_ADD_REST_API,formData);
  }

  getAddToCartUpdate(addToCartId) {
    const apiUrl = ADDTOCART__DETAILS_UPDATE_REST_API.replace("{addToCartId}", addToCartId);
    return axios.get(apiUrl);
  }

  getAddToCartCustomerUsedList(customerId) {
    const apiUrl = ADDTOCART_CUSTOMER_DETAILS_LIST_REST_API.replace("{customerId}", customerId);
    return axios.get(apiUrl);
  }

  getAddToCartDelete(addToCartId) {
    const apiUrl = ADDTOCART_DELETE_REST_API.replace("{addToCartId}", addToCartId);
    return axios.get(apiUrl);
  }

  getAddToCartDetails() {
    return axios.get(ADDTOCART_DETAILS_LIST_REST_API);
  }

  getAddToCartDetailsShowById(addToCartId) {
    const apiUrl = ADDTOCART_DETAILS_SHOW_BY_ID_REST_API.replace("{addToCartId}", addToCartId);
    return axios.get(apiUrl);
  }
}
export default new AddToCartAPI();
