import React from "react";
// import { Link } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
// import '../adminProducts/AdminProducts.css';
import "./style.css";

export const HeaderSlide = () => {
  return (
    <div>
      {/* <marquee className="h3 mt-1 mb-0 bg-secondary text-warning  border border-info border-start-0 " width="100%" height="50px" direction="right">
        This is a sample scrolling text that has scrolls in the upper direction.
        </marquee>

        <div id="carouselExampleSlidesOnly" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img src="Surface_Cleaner.jpg" className="d-block w-100 border border-primary px-auto " height="500px" alt="..."/>
              </div>
              <div className="carousel-item">
                <img src="Toilet_Cleaner.jpg" className="d-block w-100  border border-primary px-auto" height="500px" alt="..."/>
              </div>
              <div className="carousel-item">
                <img src="Triclomate_White_Floor_Cleaner.jpg" className="d-block w-100  border border-primary px-auto " height="500px" alt="..."/>
              </div>
            </div>
          </div> */}

      {/* <!--- Home Banner ---> */}

      <div
        id="carouselExampleControls"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src={`${process.env.PUBLIC_URL}/Banner.png`} class="d-block w-100" alt="..." />
          </div>
          <div class="carousel-item">
            <img src={`${process.env.PUBLIC_URL}/Banner.png`} class="d-block w-100" alt="..." />
          </div>
          <div class="carousel-item">
            <img src={`${process.env.PUBLIC_URL}/Banner.png`} class="d-block w-100" alt="..." />
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>

      <div className="container-fluid pt-5 mb-0">
        <div className="row px-xl-5 pb-3">
          <div className="col-lg-3 col-md-6 col-sm-12 pb-1 ">
            <div
              className="d-flex align-items-center bg-light mb-4 shadow shadow"
              style={{ padding: "30px" }}
            >
              <h1 className="fa fa-check text-primary m-2 mr-3"></h1>
              <h5 className="font-weight-semi-bold m-0">Quality Product</h5>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 pb-1">
            <div
              className="d-flex align-items-center bg-light mb-4 shadow"
              style={{ padding: "30px" }}
            >
              <h1 className="fa fa-shipping-fast text-primary m-2 mr-2"></h1>
              <h5 className="font-weight-semi-bold m-0">Free Shipping T & C</h5>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 pb-1">
            <div
              className="d-flex align-items-center bg-light mb-4 shadow"
              style={{ padding: "30px" }}
            >
              <h1 className="fas fa-exchange-alt text-primary m-2 mr-3"></h1>
              <h5 className="font-weight-semi-bold m-0">14-Day Return</h5>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 pb-1">
            <div
              className="d-flex align-items-center bg-light mb-4 shadow"
              style={{ padding: "30px" }}
            >
              <h1 className="fa fa-phone-volume text-primary m-2 mr-3"></h1>
              <h5 className="font-weight-semi-bold m-0">24/7 Support</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderSlide;
