import React from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./style.css";

const Footer = () => {
  return (
    <div className="container-fluid footer-main-container pt-5 pb-3">
      <div className="container">
        <div className="row footer-row">
          <div className="col-lg-2">
            <div className="company-info">
              <h5> Company Info</h5>
              <h6>
                <Link to="contact-us.html">Contact Us</Link>{" "}
              </h6>
              <h6>Social Responsibility </h6>
              <h6>
                <Link to="term-use.html">Term Of Use</Link>{" "}
              </h6>
              <h6>Fashion Blogger </h6>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="company-info">
              <h5>Get In Touch</h5>
              <p>
                <i className="fa fa-phone-alt text-primary mr-3"></i>+91 70078
                02676
              </p>
              <p>
                <i className="fa fa-envelope text-primary"></i>
                info.triclomate@gmail.com
              </p>
              <p>
                <i className="fa fa-map-marker-alt text-primary mr-3"></i>Plot
                No.32 Mehta Nagar Colony Shivpur, Varanasi-221003
              </p>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="company-info">
              <h5>Company Info</h5>
              <h6>
                {" "}
                <Link to="about-us.html">About Triclomate</Link>{" "}
              </h6>
              <h6>Social Responsibility </h6>
              <h6>Affiliate </h6>
              <h6>
                <Link to="blog.html">Fashion Blogger</Link>{" "}
              </h6>
            </div>
          </div>
          <div className="col-lg-1"></div>
          <div className="col-lg-4">
            <div className="company-info">
              <h5>Company Info</h5>
              <div className="social">
               <a href="https://www.facebook.com/profile.php?id=100084520227831"><i className="bi bi-facebook"></i>{" "}</a>
               <a href="https://www.linkedin.com/in/botanical-aura-9082ba22a/"><i className="bi bi-linkedin"></i>{" "}</a>
               <a href="https://www.instagram.com/triclomate/"><i className="bi bi-instagram"></i></a>
              </div>
              <div className="newsletter pt-4">
                <small>SIGN UP TO GET Triclomate's NOTIFICATIONS</small>
                <form className="d-flex">
                  <input
                    className="form-control me-2"
                    type="search"
                    placeholder="Your email"
                    aria-label="Search"
                  />
                  <button className="btn btn-outline-success" type="submit">
                    Subscribe
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="row copy-row pt-5">
          <div className="col-lg-4 mt-4">
            <div className="copywrite">
              <h5>©2023 Triclomate All Rights Reserved</h5>
              <div className="copywrite-links">
                <Link to="/termUse">Terms of services </Link> &nbsp;
                <Link to="/privacyPolicy">Privacy Policy</Link>{" "}
                &nbsp;
                <Link to="/termsCondition">Terms & Conditions</Link>{" "}
                &nbsp;
                <Link to="/refundPolicy">Refund Policy</Link> &nbsp;
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
