import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./style.css";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useParams } from "react-router-dom";
import ProductsAPI from "../services/ProductsAPI";
import CustomerAPI from "../services/CustomerAPI";
import ProductsOrderAPI from "../services/ProductsOrderAPI";

const CheckOut = () => {
  // const navigate = useNavigate();
  const history = useHistory();
  const { productId, customerId } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const quantityParam = queryParams.get("quantity");
  const initialQuantity = quantityParam ? parseInt(quantityParam, 10) : 1;

  const [quantity, setQuantity] = useState(initialQuantity);
  const [productDetails, setProductDetails] = useState(null);
  
  const [orderTotalPrice, setOrderTotalPrice] = useState("");
  const [customerDetails, setCustomerDetails] = useState({
    customerId: "",
    firstName: "",
    number: "",
    email: "",
    lastName: "",
    houseNo: "",
    city: "",
    state: "",
    pinCode: "",
    areaAddress: "",
  });

  const [formData, setFormData] = useState({
    orderTotalPrice: "",
    productId: "",
    customerId: "",
    paymentStatus: "Pending",
    returnStatus: "Pending",
    orderStatus: "Shipped",
    deliveryDate: "",
    productOrderId: "",
    quantity: "",
    productName: "",
    TotalPrices: "",
  });


  useEffect(() => {
    // Calculate the delivery date as needed
    const currentDate = new Date();
    const deliveryDate = new Date(currentDate);
    deliveryDate.setDate(currentDate.getDate() + 3);

    // Format the delivery date as a string (you may want to adjust the format)
    const formattedDeliveryDate = deliveryDate.toISOString().split("T")[0];

    if (productDetails?.productPrice) {
      setFormData((prevData) => ({
        ...prevData,
        orderTotalPrice: productDetails.productPrice * quantity,
        productId: productDetails.productId,
        deliveryDate: formattedDeliveryDate,
      }));
    }
  }, [productDetails, quantity]);

  useEffect(() => {
    console.log("Current productId:", productId);

    if (productId && typeof productId === "string" && productId.trim() !== "") {
      const fetchProductDetails = async () => {
        try {
          const response = await ProductsAPI.getProductDetails(productId);
          setProductDetails(response.data);
        } catch (error) {
          console.error("Error fetching product details:", error);
        }
      };

      fetchProductDetails();
    } else {
      console.error("ProductId is undefined or invalid");
      // Handle this case as needed, e.g., redirect or display an error message
    }
  }, [productId]);

  useEffect(() => {
    if (
      customerId &&
      typeof customerId === "string" &&
      customerId.trim() !== ""
    ) {
      const fetchCustomerDetails = async () => {
        try {
          const response = await CustomerAPI.getCustomerrDetails(customerId);
          setCustomerDetails(response.data);
        } catch (error) {
          console.error("Error fetching customer details:", error);
        }
      };

      fetchCustomerDetails();
    } else {
      console.error("customerId is undefined or invalid");
      // Handle this case as needed, e.g., redirect or display an error message
    }
  }, [customerId]);

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     const currentDate = new Date();
//     const deliveryDate = new Date(currentDate);
//     deliveryDate.setDate(currentDate.getDate() + 3);

//     // Format the delivery date as a string (you may want to adjust the format)
//     const formattedDeliveryDate = deliveryDate.toISOString().split("T")[0];

//     try {
//         // Prepare form data for the order
//         const formData = {
//             productId: productDetails.productId,
//             customerId: customerDetails.customerId,
//             productOrderId: "1234512", // Replace with the appropriate value
//             quantity: quantity,
//             paymentStatus: "Pending",
//             returnStatus: "Pending",
//             orderStatus: "Shipped",
//             deliveryDate: formattedDeliveryDate,
//             productName: productDetails.productName,
//         };

//         // Make the API call to add the product order
//         const response = await ProductsOrderAPI.getProductOrderAdd(formData);
//         const responseData = response.data;

//         // Update the total price state
//         setOrderTotalPrice(responseData.orderTotalPrice*quantity);

//         // Redirect to the appropriate page
//         history.push(`/myOrder/${customerDetails.customerId}`);

//         // Log the response for debugging
//         console.log("API Response:", responseData);

//         // Check the response from the server and handle accordingly
//         if (responseData.success) {
//             console.log("Add Product successful");
//         } else {
//             console.error("Add Product failed:", responseData.message);
//         }
//     } catch (error) {
//         console.error("Error during add Product:", error);

//         // Log specific error details
//         if (error.response) {
//             console.error("Server responded with:", error.response.data);
//         } else if (error.request) {
//             console.error("No response received:", error.request);
//         } else {
//             console.error("Error setting up the request:", error.message);
//         }
//     }
// };


  const handleSubmit = async (event) => {
    event.preventDefault();

    const currentDate = new Date();
    const deliveryDate = new Date(currentDate);
    deliveryDate.setDate(currentDate.getDate() + 3);

    // Format the delivery date as a string (you may want to adjust the format)
    const formattedDeliveryDate = deliveryDate.toISOString().split("T")[0];

    try {
      // Ensure customerId, productId, and productOrderId are set in formData
      const formData = {
        orderTotalPrice: productDetails.productPrice * quantity,
        productId: productDetails.productId,
        customerId: customerDetails.customerId,
        productOrderId: "1234512", // Replace with the appropriate value
        quantity: quantity,

        paymentStatus: "Pending",
        returnStatus: "Pending",
        orderStatus: "Shipped",
        deliveryDate: formattedDeliveryDate,
        productName: productDetails.productName,
      };

      const response = await ProductsOrderAPI.getProductOrderAdd(formData);
      const responseData = response.data;

      history.push(`/myOrder/${customerId}`);
      // Log the response for debugging
      console.log("API Response:", responseData);

      // Check the response from the server and handle accordingly
      if (responseData.success) {
        console.log("Add Product successful");
      } else {
        console.error("Add Product failed:", responseData.message);
      }
    } catch (error) {
      console.error("Error during add Product:", error);

      // Log specific error details
      if (error.response) {
        console.error("Server responded with:", error.response.data);

        history.push(`/customerLogin`);

      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error setting up the request:", error.message);
      }
    }
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setCustomerDetails((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  return (
    <>
      <Navbar />
      <div className="container pt-4 pb-4">
        <div className="row checkout-row">
          <div className="col-lg-7">
            <div className="contact-information">
              <h5>Contact Information</h5>
              <form className="row">
                <div className="col-lg-6 mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder={customerDetails?.firstName || ""}
                    value={customerDetails.firstName}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6 mb-3">
                  <label htmlFor="exampleInputPassword1" className="form-label">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputPassword1"
                    name="number"
                    placeholder={customerDetails?.lastName || ""}
                    value={customerDetails.lastName}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6 mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Email address
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder={customerDetails?.email || ""}
                    value={customerDetails.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6 mb-3">
                  <label htmlFor="exampleInputPassword1" className="form-label">
                    Phone Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputPassword1"
                    placeholder={customerDetails?.number || ""}
                    value={customerDetails.number}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6 mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    House No.
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder={customerDetails?.houseNo || ""}
                    value={customerDetails.houseNo}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6 mb-3">
                  <label htmlFor="exampleInputPassword1" className="form-label">
                    City
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputPassword1"
                    placeholder={customerDetails?.city || ""}
                    value={customerDetails.city}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6 mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Pin Code
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder={customerDetails?.pinCode || ""}
                    value={customerDetails.pinCode}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6 mb-3">
                  <label htmlFor="exampleInputPassword1" className="form-label">
                    State
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputPassword1"
                    placeholder={customerDetails?.state || ""}
                    value={customerDetails.state}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-lg-12 mb-6">
                  <label htmlFor="exampleInputPassword1" className="form-label">
                    Area Address
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputPassword1"
                    placeholder={customerDetails?.areaAddress || ""}
                    value={customerDetails.areaAddress}
                    onChange={handleChange}
                  />
                </div>
              </form>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="card-detailsbank">
              <form onSubmit={handleSubmit}>
                <h6>Amount Details</h6>

                <div className="total-pricebox pt-3">
                  <div>
                    <p>Quantity (item)&nbsp;&nbsp;&nbsp;&nbsp; </p>
                    <p>Subtotal (item)&nbsp;&nbsp;&nbsp;&nbsp; </p>
                    <p>Delivery Charges &nbsp;&nbsp;&nbsp;&nbsp; </p>
                    <hr />
                    <p>Total (Tax incl.) &nbsp;&nbsp;&nbsp;&nbsp; </p>
                  </div>
                  <div className="detail-ans">
                    {/* <input
                      type="text"
                      name="orderTotalPrice"
                      value={formData.orderTotalPrice}
                      onChange={handleChange}
                      hidden
                    /> */}

                    <input
                      type="text"
                      name="productId"
                      value={formData.productId}
                      onChange={handleChange}
                      hidden
                    />
                    <input
                      type="text"
                      name="customerId"
                      value={customerDetails.customerId}
                      onChange={handleChange}
                      hidden
                    />
                    <input
                      type="text"
                      name="quantity"
                      value={quantity}
                      onChange={handleChange}
                      hidden
                    />
                    <input
                      type="text"
                      name="paymentStatus"
                      value={formData.paymentStatus}
                      onChange={handleChange}
                      hidden
                    />
                    <input
                      type="text"
                      name="returnStatus"
                      value={formData.returnStatus}
                      onChange={handleChange}
                      hidden
                    />
                    <input
                      type="text"
                      name="orderStatus"
                      value={formData.orderStatus}
                      onChange={handleChange}
                      hidden
                    />
                    <input
                      type="text"
                      name="deliveryDate"
                      value={formData.deliveryDate}
                      onChange={handleChange}
                      hidden
                    />
                    {/* <input
                      type="text"
                      name="productName"
                      value={productDetails.productName}
                      onChange={handleChange}
                      hidden
                    /> */}

                    {/* {productDetails?.productPrice && (
                      <div>
                        {productDetails?.quantity && <p>{quantity}</p>}
                        <p>{formData.orderTotalPrice}</p>
                        {productDetails?.deliveryCharge && (
                          <p>{productDetails.deliveryCharge}</p>
                        )}
                        <hr />
                        <p>
                          {productDetails.deliveryCharge !== "Free"
                            ? parseInt(formData.orderTotalPrice) +
                              parseInt(productDetails.deliveryCharge)
                            : formData.orderTotalPrice}
                        </p>
                      </div>
                    )} */}

                    {productDetails?.productPrice && (
                      <div>
                        {productDetails?.quantity && <p>{quantity}</p>}
                        <p>{formData.orderTotalPrice}</p>
                        {productDetails?.deliveryCharge && (
                          <p>{productDetails.deliveryCharge}</p>
                        )}
                        <hr />
                        <p>
                          {productDetails.deliveryCharge !== "Free"
                            ? parseInt(formData.orderTotalPrice) +
                              parseInt(productDetails.deliveryCharge)
                            : formData.orderTotalPrice}
                        </p>
                        <input
                          type="text"
                          name="orderTotalPrice"
                          value={
                            productDetails.deliveryCharge !== "Free"
                              ? parseInt(formData.orderTotalPrice) +
                                parseInt(productDetails.deliveryCharge)
                              : formData.orderTotalPrice
                          }
                          onChange={handleChange}
                          hidden
                        />
                      </div>
                    )}
                  </div>
                </div>
                <br></br>
                <br></br>
                <div class="d-grid">
                  <button
                    type="submit"
                    class="btn btn-secondary btn-lg btn-rounded order-yellow-btn"
                  >
                    Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CheckOut;
