import React from "react";
// import {Link } from "react-router-dom";
import Navbar from "./Navbar";
import HeaderSlide from "./HeaderSlide";
import Products from "./Products";
import Footer from "./Footer";

// import '../adminProducts/AdminProducts.css';

export const Home = () => {
  return (
    <>
      <Navbar />
      <HeaderSlide />
      <Products />
      <Footer />
    </>
  );
};

export default Home;
