import React from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./style.css";
import Footer from "./Footer";
import Navbar from "./Navbar";

const PrivacyPolicy = () => {
  return (
    <>
      <Navbar />

      <div className="container pt-4 mb-4">
        <div className="row">
          <div className="col-lg-3">
            <div className="side-nav-policy">
              <Link to="/privacyPolicy">
                <p className="active">
                  <img src="images/privacy.png" alt="" /> &nbsp; Privacy Policy
                </p>
              </Link>
              <Link to="/termUse">
                <p>
                  <img src="images/term.png" alt="" /> &nbsp; Terms of use
                </p>
              </Link>
              <Link to="/termsCondition">
                <p>
                  <img src="images/condition.png" alt="" /> &nbsp; Terms and
                  conditions
                </p>
              </Link>
              <Link to="/refundPolicy">
                <p>
                  <img src="images/ref.png" alt="" /> &nbsp; Refund Policy
                </p>
              </Link>
            </div>
          </div>
          <div className="col-lg-9">
          <div class="terms-page">
            <h4>Privacy Policy</h4>
            <p>
              We respect your privacy. When you give us your personal
              information, we use it only to fulfill the transaction or service
              you have requested.
            </p>
            <p>
              We do not subscribe you to marketing emails without your consent.
              We do not sell or give away your contact information to any other
              entities. We do not allow the vendors who help us process
              transactions to sell or give away your information either. If you
              have questions about how we use your information please contact
              info.triclomate@gmail.com.
            </p>
            <h4>Detailed Privacy Policy</h4>
            <p>
              <b>SCOPE</b> <br />
              This policy applies to personal information collected on websites
              owned or controlled by Nielsen Norman Group (collectively referred
              to in this policy as “we”, "us" or "our"). The aim of this policy
              is to tell you how we will use any personal information we collect
              or you provide through our websites. Please read it carefully
              before you proceed. The data controller in respect of this website
              is Nielsen Norman Group.
            </p>
          </div>
        </div>
      </div>
    </div>

   
    <Footer />
    </>
  );
};

export default PrivacyPolicy;