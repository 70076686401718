import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { Link, useHistory, useParams } from "react-router-dom";
import AdminNavbar from "./AdminNavbar";
import "./admin.css";
import AddToCartAPI from "../services/AddToCartAPI";

const ViewByIdAddToCart = () => {
  // const navigate = useNavigate();
//   const history = useHistory();
  const [show, setShow] = useState(true);
  const { addToCartId } = useParams();
  const [addCart, setAddCart] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await AddToCartAPI.getAddToCartDetailsShowById(addToCartId);
        const responseData = response.data;
        console.log('Response Data:', responseData);
        setAddCart(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching product order details:', error);
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, []); // Make sure to include any dependencies if needed

  if (loading) {
    return <p>Loading...</p>;
  }
  return (
    <>
      {/* <Navbar /> */}
      <AdminNavbar />
      <main className={show ? "space-toggle" : null}>
        <header className={`header ${show ? "space-toggle" : null}`}>
          <div className="header-toggle" onClick={() => setShow(!show)}>
            <i className={`fas fa-bars ${show ? "fas fa-bars" : null}`}></i>
          </div>
        </header>

        <aside className={`sidebar ${show ? "show" : null}`}>
          <nav className="nav">
            <div>
              <Link to="#" className="nav-logo">
                <span className="nav-logo-name">Menu</span>
              </Link>

              <div className="nav-list">
                <Link
                  to="/Admindashboard"
                  className="nav-linksidebar"
                >
                  <i className="fas fa-tachometer-alt nav-link-icon"></i>
                  <span className="nav-link-name">Dashboard</span>
                </Link>
                <Link to="/customer-management" className="nav-linksidebar">
                  <i class="bi bi-person-bounding-box"></i>
                  <span className="nav-link-name">Customer Management</span>
                </Link>
                <Link to="/product-management" className="nav-linksidebar">
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Product Management</span>
                </Link>
                <Link to="/total-order" className="nav-linksidebar">
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Total Orders</span>
                </Link>  
                <Link to="/totalAddToCart" className="nav-linksidebar active-bar">
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Total AddToCart</span>
                </Link> 
                <Link to="/totalShippingOrder" className="nav-linksidebar">
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Total Shipping Orders</span>
                </Link> 
                <Link to="/TotalOrderDelivery" className="nav-linksidebar">
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Total Order Delivery</span>
                </Link>
                <hr />
                <Link className="nav-logo">
                  <span className="nav-logo-name">Other</span>
                </Link>
                <Link to="#" className="nav-linksidebar">
                  <i class="bi bi-gear"></i>
                  <span className="nav-link-name">Settings</span>
                </Link>
              </div>
            </div>
          </nav>
        </aside>

        <div className="home-container mt-5">
          <div className="container-fluid pt-1 pb-3">
            <div className="row add-customerrow">
              <div className="col-lg-8">
                <form>
                  <div className="add-customerbox">
                    <h5>AddToCart Details</h5>
                    <Link to="/totalAddToCart">
                      <button type="submit">Back</button>
                    </Link>
                    <div className="row mt-4">
                      <div className="mb-3 col-lg-6">
                        <label for="exampleInputEmail1" className="form-label">
                        AddToCart Id
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="addToCartId"
                          value={addCart.addToCartId}
                          readOnly
                        />
                      </div>
                      <div className="mb-3 col-lg-6">
                        <label for="exampleInputEmail1" className="form-label">
                          Product Order Id
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="productOrderId"
                          value={addCart.productOrderId} readOnly
                        />
                      </div>
                      <div class="mb-3 col-lg-6">
                        <label for="exampleInputEmail1" className="form-label">
                        Quantity
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="quantity"
                          value={addCart.quantity} readOnly
                        />
                      </div>
                      <div class="mb-3 col-lg-6">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                          Product Price
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="productPrice"
                          value={addCart.productPrice} readOnly
                        />
                      </div>
                      <div className="mb-3 col-lg-6">
                        <label for="exampleInputEmail1" className="form-label">
                        Customer Id
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="customerId"
                          value={addCart.customerId} readOnly
                        />
                      </div>
                      <div className="mb-3 col-lg-6">
                        <label for="exampleInputEmail1" className="form-label">
                        Product Id
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="productId"
                          value={addCart.productId} readOnly
                        />
                      </div>
                      <div className="mb-3 col-lg-6">
                        <label for="exampleInputEmail1" className="form-label">
                        Product Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="productName"
                          value={addCart.productName} readOnly
                        />
                      </div>
                      <div className="mb-3 col-lg-6">
                        <label for="exampleInputEmail1" className="form-label">
                        AddToCart Date
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="addToCartDateTime"
                          value={addCart.addToCartDateTime} readOnly
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default ViewByIdAddToCart;
