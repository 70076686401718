// import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
// import { Link } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min.js";
// import "./style.css";
// import Navbar from "./Navbar";
// import Footer from "./Footer";
// import ProductsOrderAPI from "../services/ProductsOrderAPI";
// import ProductsAPI from "../services/ProductsAPI";

// const MyOrder = () => {

//   // const { productId } = useParams();
//   // const { customerId } = useParams();
//   // const { orderId } = useParams();
//   const [customerId, setCustomerId] = useState('');
//   const [productOrderCustomerDetails, setProductOrderCustomerDetails] = useState([]);
//   // const [customerDetails, setCustomerDetails] = useState([]);
//   // const [productDetails, setProductDetails] = useState([]);

//   // useEffect(() => {
//   //   console.log("Current productId:", productId);

//   //   if (productId && typeof productId === "string" && productId.trim() !== "") {
//   //     const fetchProductDetails = async () => {
//   //       try {
//   //         const response = await ProductsAPI.getProductDetails(productId);
//   //         setProductDetails(response.data);
//   //       } catch (error) {
//   //         console.error("Error fetching product details:", error);
//   //       }
//   //     };

//   //     fetchProductDetails();
//   //   } else {
//   //     console.error("ProductId is undefined or invalid");
//   //     // Handle this case as needed, e.g., redirect or display an error message
//   //   }
//   // }, [productId]);


//   useEffect(() => {
//     console.log("Current  :", customerId);

//     if (customerId && typeof customerId === "string" && customerId.trim() !== "") {
//       const fetchOrderProductCustomerDetails = async () => {
//         try {
//           const response = await ProductsOrderAPI.getProductOrderCustomerDetails(customerId);
//           setProductOrderCustomerDetails(response.data);
//         } catch (error) {
//           console.error("Error fetching product order details:", error);
//         }
//       };

//       fetchOrderProductCustomerDetails();
//     } else {
//       console.error("orderId is undefined or invalid");
//       // Handle this case as needed, e.g., redirect or display an error message
//     }
//   }, [customerId]);

//     const handleCardClick = () => {
//         window.location.href = `/orderDetails/${customerId}`;
//         // location.href = 'details.html';
//       };
//   return (
//     <>
      // <Navbar />
      // <div className="container pt-4 pb-4">
      //   <div className="row" style={{ justifyContent: "center" }}>
      //     <div className="col-lg-9">
      //       <div className="page-tab d-flex mb-4">
      //         <div className="me-3">
      //           <Link to={`/myCart/${customerId}`}>My Cart</Link>
      //         </div>
      //         <div>
      //           <Link to={`/myOrder/${customerId}`} className="below-line">
      //             My Orders
      //           </Link>
      //         </div>
      //       </div>
//             {productOrderCustomerDetails.map((productDetails) => (
//             <div className="outer-border-box">
//               <div className="row my-orderbox" onClick={handleCardClick}>
//                 <div className="col-lg-4">
//                   <div className="order-basic-detail">
//                     <div>
//                     <img
//                   src={`data:image/jpeg;base64,${productDetails.productImg}`}
//                   alt={productDetails.productImgName}
//                   style={{ width: "150px", height: "150px" }}
//                   className="first-image"
//                 />
//                     </div>
//                     <div>
//                       <h6>
//                       {productOrderCustomerDetails.productName} <br />
//                         Map
//                       </h6>
//                       <span>QTY : 1</span>
//                       <h5>&#x20B9; {productOrderCustomerDetails.productPrice}</h5>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="col-lg-3">
//                   <div className="order-status">
//                     <h6>Status</h6>
//                     <h5>{productDetails.orderStatus} </h5>
//                   </div>
//                 </div>
//                 <div className="col-lg-3">
//                   <div className="Delivery-status">
//                     <h6>Delivery Expected by</h6>
//                     <h5>{productDetails.deliveryDate} </h5>
//                   </div>
//                 </div>
//               </div>
//               <hr />
//             </div>
//              ))}
//           </div>
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default MyOrder;


import React, { useEffect, useState } from "react";
import { useParams ,useHistory} from "react-router-dom";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./style.css";
import Navbar from "./Navbar";
import Footer from "./Footer";
import ProductsOrderAPI from "../services/ProductsOrderAPI";

const MyOrder = () => {
  
  // const navigate = useNavigate();
  const history = useHistory();
  const [selectedItem, setSelectedItem] = useState(null);

  const { customerId } = useParams();
  const [productOrderCustomerDetails, setProductOrderCustomerDetails] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrderProductCustomerDetails = async () => {
      try {
        const response = await ProductsOrderAPI.getProductOrderCustomerDetails(customerId);
        // Extract the orderProducts array from the response object
        setProductOrderCustomerDetails(response.data.orderProducts);
      } catch (error) {
        console.error("Error fetching product order details:", error);
      } finally {
        setLoading(false);
      }
    };

    if (customerId && typeof customerId === "string" && customerId.trim() !== "") {
      fetchOrderProductCustomerDetails();
    } else {
      console.error("customerId is undefined or invalid");
      // Handle this case as needed, e.g., redirect or display an error message
      setLoading(false);
    }
  }, [customerId]);

  // const handleCardClick = () => {
  //   window.location.href = `/orderDetails/${customerId}`;
  // };

  const handleCardClick = (orderId) => {
    // Use React Router to navigate to the orderDetails page
    history.push(`/orderDetails/${customerId}/${orderId}`);
  };
  return (
    <>
      <Navbar />
      <div className="container pt-4 pb-4">
        <div className="row" style={{ justifyContent: "center" }}>
          <div className="col-lg-9">
            <div className="page-tab d-flex mb-4">
              <div className="me-3">
                <Link to={`/myCart/${customerId}`}>My Cart</Link>
              </div>
              <div>
                <Link to={`/myOrder/${customerId}`} className="below-line">
                  My Orders
                </Link>
              </div>
            </div>
            {/* Display loading state while data is being fetched */}
           {loading && <p>Loading...</p>}
{!loading &&
  productOrderCustomerDetails.map((productDetails) => (
    <div key={productDetails.orderId} className="outer-border-box">
      <div className="row my-orderbox" onClick={() => handleCardClick(productDetails.orderId)}>
      {/* <div
        key={productDetails.orderId}
        className="outer-border-box"
        onClick={() => handleCardClick(productDetails.orderId)}
      > */}
        <div className="col-lg-4">
          <div className="order-basic-detail">
            <div>
              <img
                src={`data:image/jpeg;base64,${productDetails.productImg}`}
                alt={productDetails.productImgName}
                style={{ width: "150px", height: "150px" }}
                className="first-image"
              />
            </div>
            <div>
              <h6>
                {productDetails.products?.[0]?.productName} <br />
                Map
              </h6>
              <span>QTY : {productDetails.quantity}</span>
              <h5>&#x20B9; {productDetails.orderTotalPrice}</h5>
            </div>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="order-status">
            <h6>Status</h6>
            <h5>{productDetails.paymentStatus} </h5>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="Delivery-status">
            <h6>Delivery Expected by</h6>
            <h5>{productDetails.deliveryDate} </h5>
          </div>
        </div>
      </div>
    </div>
  ))}

          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MyOrder;
