import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import CustomerApi from '../services/CustomerAPI';
import axios from "axios";
import { Link, useHistory ,useParams} from 'react-router-dom';
import AdminNavbar from "./AdminNavbar";
import "./admin.css";

const UpdateCustomer = () => {
  
  // const navigate = useNavigate();
  const history = useHistory();
  const [show, setShow] = useState(true);
  // const [customer, setCustomer] = useState([]);
  const { customerId } = useParams();
  const [customer, setCustomer] = useState({
    customerId:"",
    firstName: "",
    lastName:"",
    number: "",
    email: "",
    houseNo:"",
    city:"",
    pinCode:"",
    state:"",
    areaAddress:"",
    password: "",
    customerStatus: true,
    agreeToTerms: false,
  });

  useEffect(() => {
    fetchProductDetails(); // Fetch the fetchProductDetails details on component mount
  }, []);

  const fetchProductDetails = async () => {
    try {
      const response = await axios.get(
        `http://35.154.130.93:8080/api/v1/customers/details/${customerId}`
      );
      const customerData = response.data;
      setCustomer({
        customerId: customerData.customerId,
        firstName: customerData.firstName,
        lastName: customerData.lastName,
        number: customerData.number,
        email: customerData.email,
        houseNo: customerData.houseNo,
        city: customerData.city,
        pinCode: customerData.pinCode,
        state: customerData.state,
        areaAddress: customerData.areaAddress,
        password: customerData.password,
        customerStatus: customerData.customerStatus,
        agreeToTerms: customerData.agreeToTerms,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        `http://35.154.130.93:8080/api/v1/customers/update/${customerId}`,
        customer,
        
      );
      console.log("Products update successful!", response.data);
      history.push(`/customer-management`);
    } catch (error) {
      console.error("Products update failed:", error);
    }
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setCustomer((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  return (
    <>
      {/* <Navbar /> */}
      <AdminNavbar />
      <main className={show ? "space-toggle" : null}>
        <header className={`header ${show ? "space-toggle" : null}`}>
          <div className="header-toggle" onClick={() => setShow(!show)}>
            <i className={`fas fa-bars ${show ? "fas fa-bars" : null}`}></i>
          </div>
        </header>

        <aside className={`sidebar ${show ? "show" : null}`}>
          <nav className="nav">
            <div>
              <Link to="#" className="nav-logo">
                <span className="nav-logo-name">Menu</span>
              </Link>

              <div className="nav-list">
                <Link
                  to="/Admindashboard"
                  className="nav-linksidebar"
                >
                  <i className="fas fa-tachometer-alt nav-link-icon"></i>
                  <span className="nav-link-name">Dashboard</span>
                </Link>
                <Link to="/customer-management" className="nav-linksidebar active-bar">
                  <i class="bi bi-person-bounding-box"></i>
                  <span className="nav-link-name">Customer Management</span>
                </Link>
                <Link to="/product-management" className="nav-linksidebar">
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Product Management</span>
                </Link>
                <Link to="/total-order" className="nav-linksidebar">
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Total Orders</span>
                </Link>  
                <Link to="/totalAddToCart" className="nav-linksidebar">
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Total AddToCart</span>
                </Link> 
                <Link to="/totalShippingOrder" className="nav-linksidebar">
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Total Shipping Orders</span>
                </Link> 
                <Link to="/TotalOrderDelivery" className="nav-linksidebar">
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Total Order Delivery</span>
                </Link>
                <hr />
                <Link className="nav-logo">
                  <span className="nav-logo-name">Other</span>
                </Link>
                <Link to="#" className="nav-linksidebar">
                  <i class="bi bi-gear"></i>
                  <span className="nav-link-name">Settings</span>
                </Link>
              </div>
            </div>
          </nav>
        </aside>

        <div className="home-container mt-5">
          <div className="container-fluid pt-1 pb-3">
            <div className="row add-customerrow">
              <div className="col-lg-8">
                <form onSubmit={handleSubmit}>
                  <div className="add-customerbox">
                  <h5>Update a customer</h5>
                    <div className="row mt-4">
                      <div className="mb-3 col-lg-6">
                        <label for="exampleInputEmail1" className="form-label">
                          First Name
                        </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="First name"
                    name="firstName"
                    value={customer.firstName}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3 col-lg-6">
                        <label for="exampleInputEmail1" className="form-label">
                          Last Name
                        </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Last name"
                    name="lastName"
                    value={customer.lastName}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div class="mb-3 col-lg-6">
                        <label for="exampleInputEmail1" className="form-label">
                    Phone Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter your customer phone number"
                    autoComplete="off"
                    onKeyPress={(event) => {
                      const charCode = event.charCode;
                      if (
                        charCode < 48 ||
                        charCode > 57 ||
                        event.target.value.length >= 10
                      ) {
                        event.preventDefault();
                      }
                    }}
                    name="phone"
                    value={customer.number}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div class="mb-3 col-lg-6">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="akkyeu02@gmail.com"
                    name="email"
                    value={customer.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3 col-lg-6">
                        <label for="exampleInputEmail1" className="form-label">
                        House No.
                        </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="houseNo"
                    name="houseNo"
                    value={customer.houseNo}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3 col-lg-6">
                        <label for="exampleInputEmail1" className="form-label">
                        City
                        </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="city"
                    name="city"
                    value={customer.city}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3 col-lg-6">
                        <label for="exampleInputEmail1" className="form-label">
                        Pin Code
                        </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="pinCode"
                    name="pinCode"
                    value={customer.pinCode}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3 col-lg-6">
                        <label for="exampleInputEmail1" className="form-label">
                        State
                        </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="state"
                    name="state"
                    value={customer.state}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3 col-lg-6">
                        <label for="exampleInputEmail1" className="form-label">
                        Area Address
                        </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="areaAddress"
                    name="areaAddress"
                    value={customer.areaAddress}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3 col-lg-6">
                  <label htmlFor="exampleInputPassword1" className="form-label">
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="exampleInputPassword1"
                    placeholder="**********"
                    name="password"
                    value={customer.password}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3 col-lg-6">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                          Status
                        </label>
                        <select
                          className="form-select"
                          id="customerStatus"
                          name="customerStatus"
                          value={customer.customerStatus}
                          onChange={handleChange}
                        >
                          <option value={true}>Active</option>
                          <option value={false}>Inactive</option>
                        </select>
                      </div>
                </div>
                <div className="add-customerbtn pt-4">
                      <button type="submit">Update Customer</button>
                    </div>
                  </div>
                </form>
            </div>
            </div>
          </div>
        </div>
        </main>
    </>
  );
};

export default UpdateCustomer;

