import React, { useEffect, useState } from "react";
import "./admin.css";
import { Link, useHistory } from "react-router-dom";
import CustomerApi from '../services/CustomerAPI';
import axios from "axios";
import AdminNavbar from "./AdminNavbar";
import 'bootstrap-icons/font/bootstrap-icons.css';

const Customer = () => {
  // const navigate = useNavigate();
  const history = useHistory();
  const [show, setShow] = useState(true);
  const [clients, setClients] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await CustomerApi.getCustomerDetailsList();
      const responseData = response.data;

      if (Array.isArray(responseData)) {
        setClients(responseData);
      } else {
        console.error("API did not return an array:", responseData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdate = async (customerId) => {
    try {
      // Redirect to the user details page with the customerId as a parameter
      history.push(`/updatecustomer/${customerId}`);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (customerId) => {
    try {
      await axios.delete(
        `http://35.154.130.93:8080/cctv/api/v1/customer/delete/${customerId}`
      );
      setClients(clients.filter((client) => client.customerId !== customerId));
    } catch (error) {
      console.log(error);
    }
  };

  // const handleStatusChange = async (client, newStatus) => {
  //   try {
  //     const updatedClient = { ...client, customerStatus: newStatus };
  //     // await axios.put(`http://localhost:8080/cctv/api/v1/customer/${client.customerId}`, updatedClient);
  //     await axios.post(
  //       `http://35.154.130.93:8080/api/v1/customers/update/${client.customerId}`,
  //       updatedClient
  //     );
  //     fetchData(); // Refresh the client data
  //   } catch (error) {
  //     console.error("Error updating client status:", error);
  //   }
  // };

  const handleStatusChange = async (client, newStatus) => {
    try {
      const updatedClient = { ...client, customerStatus: newStatus };
      // await axios.put(`http://localhost:8080/cctv/api/v1/customer/${client.customerId}`, updatedClient);
      await axios.post(
        `http://35.154.130.93:8080/api/v1/customers/update/${client.customerId}`,
        updatedClient
      );
      fetchData(); // Refresh the client data
    } catch (error) {
      console.error("Error updating client status:", error);
    }
  };

  return (
    <>
    <AdminNavbar />
      {/* <Adminnav /> */}

      <main className={show ? "space-toggle" : null}>
        <header className={`header ${show ? "space-toggle" : null}`}>
          <div className="header-toggle" onClick={() => setShow(!show)}>
            <i className={`fas fa-bars ${show ? "fas fa-bars" : null}`}></i>
          </div>
        </header>

        <aside className={`sidebar ${show ? "show" : null}`}>
          <nav className="nav">
            <div>
              <Link to="#" className="nav-logo">
                <span className="nav-logo-name">Menu</span>
              </Link>

              <div className="nav-list">
                <Link
                  to="/Admindashboard"
                  className="nav-linksidebar"
                >
                  <i className="fas fa-tachometer-alt nav-link-icon"></i>
                  <span className="nav-link-name">Dashboard</span>
                </Link>
                <Link to="/customer-management" className="nav-linksidebar active-bar">
                  <i class="bi bi-person-bounding-box"></i>
                  <span className="nav-link-name">Customer Management</span>
                </Link>
                <Link to="/product-management" className="nav-linksidebar">
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Product Management</span>
                </Link>
                <Link to="/total-order" className="nav-linksidebar">
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Total Orders</span>
                </Link>  
                <Link to="/totalAddToCart" className="nav-linksidebar">
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Total AddToCart</span>
                </Link> 
                <Link to="/totalShippingOrder" className="nav-linksidebar">
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Total Shipping Orders</span>
                </Link> 
                <Link to="/TotalOrderDelivery" className="nav-linksidebar">
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Total Order Delivery</span>
                </Link>
                <hr />
                <Link className="nav-logo">
                  <span className="nav-logo-name">Other</span>
                </Link>
                <Link to="#" className="nav-linksidebar">
                  <i class="bi bi-gear"></i>
                  <span className="nav-link-name">Settings</span>
                </Link>
              </div>
            </div>
          </nav>
        </aside>

        <div className="home-container mt-5">
          <div className="container-fluid">
            <div className="row">
              <div class="row">
                <div class="table-box">
                  <div class="pt-2">
                    <div className="outer-filter">
                      <p>Customer Details</p>
                      <div className="new-filter">
                        <div>
                          <input
                            class="search"
                            type="search"
                            placeholder="Search..."
                          />
                        </div>
                        <div>
                        </div>
                      </div>
                    </div>
                    <table>
                      <thead>
                        <tr>
                          <th>Customer ID</th>
                          <th>Customer Name</th>
                          <th>Email Id</th>
                          <th>Phone Number</th>
                          <th>Address</th>
                          <th>state</th>
                          <th>Pin Code</th>
                          <th>Area Address</th>
                          <th>Customer status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {clients.length > 0 ? (
                          clients.map((client) => (
                            <tr key={client.customerId}>
                            <td>{client.customerId}</td>
                              <td>{client.firstName} {client.lastName}</td>
                              <td>{client.email}</td>
                              <td>{client.number}</td>
                              <td>{client.houseNo},{client.city}</td>
                              <td>{client.state}</td>
                              <td>{client.pinCode}</td>
                              <td>{client.areaAddress}</td>
                              <td>
                                <select
                                  className="form-select"
                                  aria-label="Select status"
                                  value={client.customerStatus ? "1" : "0"}
                                  onChange={(e) =>
                                    handleStatusChange(
                                      client,
                                      e.target.value === "1"
                                    )
                                  }
                                >
                                  <option value="1">Active</option>
                                  <option value="0">Inactive</option>
                                </select>
                              </td>
                              <td>
                                <i
                                  className="bi bi-pencil-square"
                                  onClick={() =>
                                    handleUpdate(client.customerId)
                                  }
                                ></i>
                                &nbsp;
                                <i
                                  className="bi bi-trash3"
                                  onClick={() =>
                                    handleDelete(client.customerId)
                                  }
                                ></i>
                                &nbsp;
                                {/* <i className="bi bi-eye"></i> */}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="8">No Customer found.</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Customer;
