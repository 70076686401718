import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./style.css";
import Navbar from "./Navbar";
import Footer from "./Footer";
import AddToCartAPI from "../services/AddToCartAPI";

const MyCart = () => {

  const { customerId ,productId} = useParams();
  const [productOrderDetails, setProductOrderDetails] = useState([]);

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        // Replace the old API call with the new one
        const response = await AddToCartAPI.getAddToCartCustomerUsedList(customerId);
        setProductOrderDetails(response.data);
      } catch (error) {
        console.error("Error fetching product order details:", error);
      }
    };

    // Call the fetchProductDetails function
    fetchProductDetails();
  }, [customerId]);

  const handleDeleteItem = async (addToCartId) => {
    try {
      // Call the API to delete the item
      await AddToCartAPI.getAddToCartDelete(addToCartId);

      // Update the state to reflect the deletion
      setProductOrderDetails((prevDetails) =>
        prevDetails.filter((item) => item.addToCartId !== addToCartId)
      );
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  return (
    <>
      <Navbar />
      <div className="container pt-4 pb-4">
        <div className="row" style={{ justifyContent: "center" }}>
          <div className="col-lg-9">
            <div className="page-tab d-flex mb-4">
              <div className="me-3">
                <Link to={`/mycart/${customerId}`} className="below-line">
                  My cart
                </Link>
              </div>
              <div>
                <Link to={`/myOrder/${customerId}`}>My Orders</Link>
              </div>
            </div>
            <div className="border-column">
            <div className="border-column">
  {productOrderDetails.map((product, index) => (
    <div
      key={index}
      className="row"
      style={{
        justifyContent: "space-evenly",
        alignItems: "center"
      }}
    >
      <div className="col-lg-4">
        <div
          className="cart-content d-flex"
          style={{ alignItems: "center" }}
        >
          <div className="me-3">
            <img src="images/cart.png" alt="" />
          </div>
          <div>
            <h4>{product.productName}</h4>
            <p>#{product.productOrderId}</p>
          </div>
        </div>
      </div>
      <div className="col-lg-1">
        <div className="mb-3">
          <input
            type="number"
            className="form-control"
            placeholder={product.quantity}
            id="exampleInputPassword1"
          />
        </div>
      </div>
      <div className="col-lg-2">
        <div className="cart-price">
          <h6>&#x20B9;{product.productPrice}</h6>
        </div>
      </div>
      <div className="col-lg-1">
      <i
                className="fa-solid fa-xmark"
                onClick={() => handleDeleteItem(product.addToCartId)}
                style={{ cursor: "pointer" }}
              ></i>
              <Link to={`/singleProductDetails/${customerId}/${product.productId}`}>
                <i
                  className="bi bi-basket"
                  style={{ cursor: "pointer" }}
                ></i>
              </Link>
      </div>      
    <hr/>
    </div>
  ))}
  {/* <hr />
  <div className="row">
    <div className="col-lg-5">
      <hr />
      <div
        className="d-flex cart-total"
        style={{ justifyContent: "space-between" }}
      >
        <p>Cart Total</p>
        <h5>&#x20B9;</h5>
      </div>
    </div>
  </div> */}
</div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MyCart;
