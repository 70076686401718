import React, { useEffect, useState } from "react";
import "./admin.css";
import { Link, useHistory ,useParams} from "react-router-dom";
import AdminNavbar from "./AdminNavbar";
import 'bootstrap-icons/font/bootstrap-icons.css';
import TotalOrderAPI from "../services/AdminAPI/TotalOrderAPI";

const CustomerViewOderShipping = () => {
  // const navigate = useNavigate();
  const history = useHistory();
  const { customerId } = useParams();
  const [show, setShow] = useState(true);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await TotalOrderAPI.getProductOrderCustomerDetailsById(customerId);
      const responseData = response.data;

      if (Array.isArray(responseData)) {
        setOrders(responseData);
      } else {
        console.error("API did not return an array:", responseData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleView = async (orderId) => {
    try {
      // Redirect to the user details page with the customerId as a parameter
      history.push(`/ViewOrderShipping/${orderId}`);
    } catch (error) {
      console.log(error);
    }
  };

//   const handleDelete = async (customerId) => {
//     try {
//       await axios.delete(
//         `http://35.154.130.93:8080/cctv/api/v1/customer/delete/${customerId}`
//       );
//       setOrders(orders.filter((order) => order.customerId !== customerId));
//     } catch (error) {
//       console.log(error);
//     }
//   };

  // const handleStatusChange = async (client, newStatus) => {
  //   try {
  //     const updatedClient = { ...client, customerStatus: newStatus };
  //     // await axios.put(`http://localhost:8080/cctv/api/v1/customer/${client.customerId}`, updatedClient);
  //     await axios.post(
  //       `http://35.154.130.93:8080/api/v1/customers/update/${client.customerId}`,
  //       updatedClient
  //     );
  //     fetchData(); // Refresh the client data
  //   } catch (error) {
  //     console.error("Error updating client status:", error);
  //   }
  // };

//   const handleStatusChange = async (client, newStatus) => {
//     try {
//       const updatedClient = { ...client, customerStatus: newStatus };
//       // await axios.put(`http://localhost:8080/cctv/api/v1/customer/${client.customerId}`, updatedClient);
//       await axios.post(
//         `http://35.154.130.93:8080/api/v1/customers/update/${client.customerId}`,
//         updatedClient
//       );
//       fetchData(); // Refresh the client data
//     } catch (error) {
//       console.error("Error updating client status:", error);
//     }
//   };

  return (
    <>
    <AdminNavbar />
      {/* <Adminnav /> */}

      <main className={show ? "space-toggle" : null}>
        <header className={`header ${show ? "space-toggle" : null}`}>
          <div className="header-toggle" onClick={() => setShow(!show)}>
            <i className={`fas fa-bars ${show ? "fas fa-bars" : null}`}></i>
          </div>
        </header>

        <aside className={`sidebar ${show ? "show" : null}`}>
          <nav className="nav">
            <div>
              <Link to="#" className="nav-logo">
                <span className="nav-logo-name">Menu</span>
              </Link>

              <div className="nav-list">
                <Link
                  to="/Admindashboard"
                  className="nav-linksidebar"
                >
                  <i className="fas fa-tachometer-alt nav-link-icon"></i>
                  <span className="nav-link-name">Dashboard</span>
                </Link>
                <Link to="/customer-management" className="nav-linksidebar">
                  <i class="bi bi-person-bounding-box"></i>
                  <span className="nav-link-name">Customer Management</span>
                </Link>
                <Link to="/product-management" className="nav-linksidebar">
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Product Management</span>
                </Link>
                <Link to="/total-order" className="nav-linksidebar">
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Total Orders</span>
                </Link>  
                <Link to="/totalAddToCart" className="nav-linksidebar">
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Total AddToCart</span>
                </Link> 
                <Link to="/totalShippingOrder" className="nav-linksidebar active-bar">
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Total Shipping Orders</span>
                </Link> 
                <Link to="/TotalOrderDelivery" className="nav-linksidebar ">
                  <i class="bi bi-webcam"></i>
                  <span className="nav-link-name">Total Order Delivery</span>
                </Link>
                <hr />
                <Link className="nav-logo">
                  <span className="nav-logo-name">Other</span>
                </Link>
                <Link to="#" className="nav-linksidebar">
                  <i class="bi bi-gear"></i>
                  <span className="nav-link-name">Settings</span>
                </Link>
              </div>
            </div>
          </nav>
        </aside>

        <div className="home-container mt-5">
          <div className="container-fluid">
            <div className="row">
              <div class="row">
                <div class="table-box">
                  <div class="pt-2">
                    <div className="outer-filter">
                      <p>Total Order Delivery Details Customer Ways</p>
                      <div className="new-filter">
                        <div>
                          <input
                            class="search"
                            type="search"
                            placeholder="Search..."
                          />
                        </div>
                        <div>
                        </div>
                      </div>
                    </div>
                    <table>
                      <thead>
                        <tr>
                          <th>Order Id</th>
                          <th>Product Order Id</th>
                          <th>Customer Id</th>
                          <th>Product Id</th>
                          <th>Product Price</th>
                          <th>Order Price</th>
                          <th>Product Name</th>
                          <th>Quantity</th>
                          <th>Payment Status</th>
                          <th>Delivery Date</th>
                          <th>Order Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                      {orders.length > 0 ? (
                          orders.map(
                            (order) =>
                              // Only render rows where orderStatus is "Completed"
                              order.orderStatus !== "Completed" && (
                                <tr key={order.orderId}>
                            <td>{order.orderId}</td>
                            <td>{order.productOrderId}</td>
                              <td>{order.customerId}</td>
                              <td>{order.productId}</td>
                              <td>{order.productPrice}</td>
                              <td>{order.orderTotalPrice}</td>
                              <td>{order.productName}</td>
                              <td>{order.quantity}</td>
                              <td>{order.paymentStatus}</td>
                              <td>{order.deliveryDate}</td>
                              <td>{order.orderStatus}</td>
                              <td>
                                {/* <i
                                  className="bi bi-pencil-square"
                                  onClick={() =>
                                    handleUpdate(order.customerId)
                                  }
                                ></i>
                                &nbsp;
                                <i
                                  className="bi bi-trash3"
                                  onClick={() =>
                                    handleDelete(order.customerId)
                                  }
                                ></i> */}
                                {/* &nbsp; */}

                                <i
                                 className="bi bi-eye"
                                  onClick={() =>
                                    handleView(order.orderId)
                                  }
                                ></i> 
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="11">No Order found.</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default CustomerViewOderShipping;
